import React from 'react';
import cx from "../../lib/classnames.js";
import ArrowSvg from '../../_images/arrow.svg';
import ArrowFullSvg from '../../_images/arrow-full.svg';
import * as s from './Icons.module.scss';
const Arrow = ({ dir, full, ...rest }) => {
    const className = cx(s, 'icon-arrow', { [dir]: dir });
    return full ? (React.createElement(ArrowFullSvg, { ...rest, className: className })) : (React.createElement(ArrowSvg, { ...rest, className: className }));
};
export default Arrow;
