/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ description, keywords, image, href, title }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					m: siteMetadata {
						title
						description
						keywords
					}
				}
			}
		`
	)

	const metaDescription = description || site.m.description
	const metaTitle = title || site.m.title
	const metaKeywords = keywords || site.m.keywords
	const metaUrl = href || site.m.url

	return (
		<Helmet>
			{metaTitle && <title>{metaTitle}</title>}
			{metaTitle && (
				<meta name='apple-mobile-web-app-title' content={metaTitle} />
			)}
			{metaTitle && <meta name='application-name' content={metaTitle} />}
			{metaDescription && (
				<meta name='description' content={metaDescription} />
			)}
			{metaKeywords && (
				<meta
					name='keywords'
					content={metaKeywords.join(`, `).toLowerCase()}
				/>
			)}

			{metaTitle && <meta property='og:title' content={metaTitle} />}
			{metaDescription && (
				<meta property='og:description' content={metaDescription} />
			)}
			{image && <meta property='og:image' content={image} />}
			{metaUrl && <meta property='og:url' content={metaUrl} />}

			{metaTitle && <meta name='twitter:title' content={metaTitle} />}
			{metaDescription && (
				<meta name='twitter:description' content={metaDescription} />
			)}
			{image && <meta name='twitter:image' content={image} />}
			{image && <meta name='twitter:card' content={image} />}
			{metaDescription && (
				<meta name='twitter:image:alt' content={metaDescription} />
			)}
		</Helmet>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
}

export default SEO

SEO.metadata = ({ seo }) => seo.page.metaData

export const query = graphql`
	fragment SEOFragment on File {
		seo: childMarkdownRemark {
			page: frontmatter {
				metaData {
					title
					description
					keywords
				}
			}
		}
	}
`
