"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkOverlap = exports.discontinuousOverlaps = exports.overlaps = exports.isRange = exports.isDiscontinuous = void 0;
const array_1 = require("./array");
function isDiscontinuous(v) {
    if (!v)
        return false;
    return Array.isArray(v[0]);
}
exports.isDiscontinuous = isDiscontinuous;
function isRange(v) {
    if (!v)
        return false;
    return !Array.isArray(v[0]);
}
exports.isRange = isRange;
function overlaps(range, c, asRange = true) {
    if (isRange(range)) {
        const [a, b] = range;
        if (asRange) {
            if (!b && b !== 0) {
                return (c - a) * (a - c) >= 0;
            }
            return (c - a) * (b - c) >= 0;
        }
        return [a, b].includes(c);
    }
    if (isDiscontinuous(range)) {
        return range.some((r) => overlaps(r, c));
    }
}
exports.overlaps = overlaps;
function rangesOverlap(range1, range2) {
    const start1 = Math.min(...range1);
    const end1 = Math.max(...range1);
    const start2 = Math.min(...range2);
    const end2 = Math.max(...range2);
    // If one range starts after the other ends, or vice versa, they don't overlap
    return !(end1 < start2 || end2 < start1);
}
function discontinuousOverlaps(ranges, i) {
    return ranges.some((range) => overlaps(range, i));
}
exports.discontinuousOverlaps = discontinuousOverlaps;
function checkOverlap(ranges) {
    return ranges.some((range, i) => {
        const otherRanges = (0, array_1.removeAt)(ranges, i);
        if (isRange(range)) {
            return otherRanges.some((r) => {
                if (isRange(r)) {
                    return rangesOverlap(range, r);
                }
                if (isDiscontinuous(r)) {
                    return r.some((s) => isRange(s) && rangesOverlap(range, s));
                }
            });
        }
        if (isDiscontinuous(range)) {
            return otherRanges.some((r) => {
                if (isRange(r)) {
                    return range.some((s) => isRange(s) && rangesOverlap(s, r));
                }
                if (isDiscontinuous(r)) {
                    return r.some((s) => isRange(s) &&
                        range.some((t) => isRange(t) && rangesOverlap(s, t)));
                }
            });
        }
    });
}
exports.checkOverlap = checkOverlap;
