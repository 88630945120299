"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertAssignable = exports.assertNot = exports.assert = void 0;
function assert(msg) { }
exports.assert = assert;
function assertNot(msg) { }
exports.assertNot = assertNot;
function assertAssignable(t, msg) { }
exports.assertAssignable = assertAssignable;
assert('And<true, true> is true');
assertNot('And<true, false> is false');
assertNot('And<false, true> is false');
assertNot('And<false, false> is false');
assert('Or<true, true> is true');
assert('Or<true, false> is true');
assert('Or<false, true> is true');
assertNot('Or<false, false> is false');
assert('Not<false> is true');
assertNot('Not<true> is false');
assert('Extends<> handles simple types');
assert('Extends<> handles union types');
assert('Extends<> handles object types');
assert('Extends<> handles object types');
assertNot('Extends<> is false for mismatched types');
assertNot('Extends<> is false for different values of the same type');
assert('Extends<> handles mismatched unions');
assertNot('Extends<> is false for object types with missing properties');
assert('Extends<> is false even when the first object extends the second but does not match');
assertNot('Extends<> is false for object types with mismatched property types');
assert('Extends<> handles optional properties');
assert('Equal<> handles simple types');
assert('Equal<> handles union types');
assert('Equal<> handles object types');
assert('Equal<> handles for optional properties');
assertNot('Equal<> is false for mismatched types');
assertNot('Equal<> is false for different values of the same type');
assertNot('Equal<> is false for mismatched unions');
assertNot('Equal<> is false for object types with missing properties');
assertNot('Equal<> is false even when the first object extends the second but does not match');
assertNot('Equal<> is false for object types with mismatched property types');
assertNot('Equal<> is false for optional properties that do not match');
assert('Never<never> should be true');
assertNot('Never<{}> should be false');
