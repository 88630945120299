import React from 'react';
import cx from "../../lib/classnames.js";
import Input from "../Input/index.js";
import FormField from "../FormField/index.js";
import * as s from './NumberField.module.scss';
function NumberField(props) {
    const { value, onChange, onBlur, nullable, ...rest } = props;
    return (React.createElement(FormField, { ...rest, value: toStr(value), className: cx(s, 'number-field'), onChange: (v, e) => onChange?.(toVal(v), e), onBlur: (v, e) => onBlur?.(toVal(v), e) }, (thru) => React.createElement(Input, { type: 'number', ...thru })));
    function toStr(val) {
        return val?.toString() ?? '';
    }
    function toVal(str) {
        return nullable && str === '' ? null : Number(str);
    }
}
export default NumberField;
export function DecimalField(props) {
    return React.createElement(NumberField, { min: 0, step: '.01', ...props });
}
