import React from 'react';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { Group } from '@visx/group';
import { string } from '@ordaos/util';
import cx from "../lib/classnames.js";
import useAsync, { Async } from "../hooks/use-async.js";
import useStatelyProp from "../hooks/use-stately-prop.js";
import Button from "../components/Button/index.js";
import * as Icon from "../components/Icon/index.js";
import * as s from './BaseChart.module.scss';
function BaseChart(props) {
    const { controls, title, tooltip, width = 300, height = 300, marginLeft = 0, marginRight = 0, marginTop = 0, marginBottom = 0, layout, header, collapsible, ...rest } = props;
    const [collapsed, setCollapsed] = useStatelyProp(collapsible === 'closed');
    const { showTooltip, hideTooltip, tooltipOpen, tooltipData, tooltipLeft = 0, tooltipTop = 0, } = useTooltip();
    const dataRaw = isAsync(props) && props.data;
    const data = useAsync(async () => {
        if (isAsync(props)) {
            return props.data;
        }
    }, [dataRaw]);
    return (React.createElement("figure", { className: cx(s, 'base-chart--wrapper', {
            [layout]: layout,
        }, title && `chart-${string.dashify(title)}`) },
        title && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cx(s, 'base-chart--wrapper--header') },
                React.createElement("h2", { className: cx(s, 'base-chart--wrapper--header-title') }, title),
                collapsible && (React.createElement(Button, { icon: true, onClick: () => {
                        return setCollapsed(!collapsed);
                    } }, collapsed ? React.createElement(Icon.Show, null) : React.createElement(Icon.Hide, null))),
                header && (React.createElement("div", { className: cx(s, 'base-chart--wrapper--header-actions') }, data.status === 'completed' &&
                    header(data.value)))))),
        !collapsed && (React.createElement("div", { className: cx(s, title
                ? 'base-chart--wrapper--content'
                : 'base-chart--content') },
            isAsync(props) && (React.createElement(Async, { value: data }, (d) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement("svg", { width: width + marginLeft + marginRight, height: height +
                            marginTop +
                            marginBottom, className: cx(s, 'base-chart--container'), ...rest },
                        React.createElement(Group, { transform: `translate(${marginLeft},${marginTop})`, onMouseMove: () => tooltip &&
                                showTooltip({
                                    tooltipLeft: tooltip.left,
                                    tooltipTop: tooltip.top,
                                    tooltipData: tooltip.text,
                                }), onMouseOut: hideTooltip }, props.children(d)))));
            })),
            isSync(props) && (React.createElement("svg", { width: width + marginLeft + marginRight, height: height + marginTop + marginBottom, className: cx(s, 'base-chart--container'), ...rest },
                React.createElement(Group, { transform: `translate(${marginLeft},${marginTop})`, onMouseMove: () => tooltip &&
                        showTooltip({
                            tooltipLeft: tooltip.left,
                            tooltipTop: tooltip.top,
                            tooltipData: tooltip.text,
                        }), onMouseOut: hideTooltip }, props.children))),
            tooltipOpen && (React.createElement(TooltipWithBounds, { key: tooltipTop * tooltipLeft, top: tooltipTop + marginTop, left: tooltipLeft + marginLeft, offsetTop: 0, offsetLeft: 0 }, tooltipData)),
            !!controls && (React.createElement("div", { className: cx(s, 'base-chart--wrapper--content-controls') },
                React.createElement("div", null,
                    title,
                    " Controls"),
                controls))))));
}
export default BaseChart;
function isAsync(props) {
    return !!props.hasOwnProperty('data');
}
function isSync(props) {
    return !props.hasOwnProperty('data');
}
