"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.snakeCase = exports.camelCase = exports.removeUndefined = exports.value = exports.exists = exports.clone = exports.split = exports.pluck = exports.omitValues = exports.pickValues = exports.omit = exports.pick = exports.remove = exports.prop = exports.merge = exports.zip = exports.reduce = exports.filter = exports.map = void 0;
const s = __importStar(require("./string"));
function isObject(obj) {
    return obj && typeof obj === 'object' && obj.constructor === Object;
}
function map(obj, fn) {
    return reduce(obj, (acc, v, k) => ({ ...acc, [k]: fn(v, k) }), {});
}
exports.map = map;
function filter(obj, fn) {
    return reduce(obj, (acc, v, k) => (fn(v, k) ? { ...acc, [k]: v } : acc), {});
}
exports.filter = filter;
function reduce(obj, fn, init) {
    return Object.entries(obj).reduce((acc, [k, v]) => fn(acc, v, k), init);
}
exports.reduce = reduce;
function zip(obj) {
    const keys = Object.keys(obj);
    const len = Math.min(...keys.map((k) => obj[k].length));
    return Array.from({ length: len }, (_, i) => keys.reduce((acc, k) => ({ ...acc, [k]: obj[k][i] }), {}));
}
exports.zip = zip;
function merge(o1, o2, opts) {
    if (!o1)
        return o2;
    if (!o2)
        return o1;
    const { shallow = false } = opts ? opts : {};
    if (shallow) {
        return { ...o1, ...o2 };
    }
    return Object.entries(o2).reduce((acc, [k, v]) => {
        if (!isObject(v)) {
            return { ...acc, [k]: v };
        }
        if (k in acc) {
            return { ...acc, [k]: merge(acc[k], v, { shallow: false }) };
        }
        return { ...acc, [k]: v };
    }, o1);
}
exports.merge = merge;
function prop(k) {
    return (item) => item[k];
}
exports.prop = prop;
function remove(obj, ...keys) {
    return split(obj, ...keys)[1];
}
exports.remove = remove;
function pick(obj, ...keys) {
    return split(obj, ...keys)[0];
}
exports.pick = pick;
exports.omit = remove;
function pickValues(obj, ...values) {
    // @ts-ignore TODO: This entire module needs to be fixed for types
    return filter(obj, (v) => values.includes(v));
}
exports.pickValues = pickValues;
function omitValues(obj, ...values) {
    // @ts-ignore TODO: This entire module needs to be fixed for types
    return filter(obj, (v) => !values.includes(v));
}
exports.omitValues = omitValues;
function pluck(path) {
    const keys = path.split(`.`);
    return (obj) => keys.reduce((acc, k) => acc && acc[k], obj);
}
exports.pluck = pluck;
function split(obj, ...keys) {
    return Object.entries(obj).reduce(([inc, exc], [k, v]) => {
        return keys.includes(k)
            ? [{ ...inc, [k]: v }, exc]
            : [inc, { ...exc, [k]: v }];
    }, [{}, {}]);
}
exports.split = split;
function clone(v) {
    return JSON.parse(JSON.stringify(v));
}
exports.clone = clone;
function exists(v) {
    return v !== null && v !== undefined;
}
exports.exists = exists;
function value(v) {
    if (!v)
        return;
    return typeof v === 'function' ? v : prop(v);
}
exports.value = value;
function removeUndefined(o) {
    return filter(o, (v) => v !== undefined);
}
exports.removeUndefined = removeUndefined;
function camelCase(obj) {
    if (Array.isArray(obj))
        return obj.map(camelCase);
    if (!obj || typeof obj !== 'object' || obj instanceof Date)
        return obj;
    return reduce(obj, (acc, v, k) => {
        const ck = typeof k === 'string' ? s.camelCase(k) : k;
        return { ...acc, [ck]: camelCase(v) };
    }, {}); // Prevent removing intentionally empty props
}
exports.camelCase = camelCase;
function snakeCase(obj) {
    if (Array.isArray(obj))
        return obj.map(snakeCase);
    if (!obj || typeof obj !== 'object' || obj instanceof Date)
        return obj;
    return reduce(obj, (acc, v, k) => {
        const ck = typeof k === 'string' ? s.snakeCase(k) : k;
        return { ...acc, [ck]: snakeCase(v) };
    }, {}); // Prevent removing intentionally empty props
}
exports.snakeCase = snakeCase;
