import React from 'react';
import fuzzy from 'fuzzy';
import { array } from '@ordaos/util';
import Table from "./Table.js";
function ArrayTable({ items: items_, ...props }) {
    return (React.createElement(Table, { ...props, items: [
            async ({ filters, sort, limit, offset }) => {
                const items = await items_;
                const filtered = filters.length
                    ? array.filter(items, ...filters.map(toFilterFn))
                    : items;
                const sorted = sort
                    ? array.sort(filtered, sort.column.value, sort.direction)
                    : filtered;
                const paginated = sorted.slice(offset, offset + (limit ?? Infinity));
                return {
                    items: paginated,
                    total: sorted.length,
                };
            },
            [items_],
        ] }));
}
export default ArrayTable;
ArrayTable.Column = Table.Column;
function toFilterFn(f) {
    return (item) => {
        if (f.expected == null)
            return true;
        const expected = prep(f.expected);
        const actuals = f.column === 'any' ? Object.values(item) : [f.column.value(item)];
        return actuals.some((v) => run(f, prep(v), expected));
        function prep(v) {
            return (typeof v === 'string' ? v.toLowerCase() : v);
        }
        function run(f, actual, expected) {
            switch (f.by) {
                case 'eq':
                    return actual === expected;
                case 'ne':
                    return actual !== expected;
                case 'gt':
                    return actual > expected;
                case 'lt':
                    return actual < expected;
                case 'gte':
                    return actual >= expected;
                case 'lte':
                    return actual <= expected;
                case 'fuzzy':
                    return fuzzy.test(`{expected}`, `${actual}`);
                case 'contains':
                    return `${actual}`.includes(`${expected}`);
                case 'starts':
                    return `${actual}`.startsWith(`${expected}`);
                case 'ends':
                    return `${actual}`.endsWith(`${expected}`);
                case 'in':
                    return `${expected}`.includes(`${actual}`);
                default:
                    throw new Error(`Unknown filter by: ${f.by}`);
            }
        }
    };
}
