import React, { useState, useMemo } from 'react';
import Button from "../Button/index.js";
import Anchor from "../Anchor/index.js";
import SelectField from "../SelectField/index.js";
import * as Icons from "../Icon/index.js";
import cx from "../../lib/classnames.js";
import * as s from './StructureViewer.module.scss';
import * as t from '../../_tokens/index.theo.module';
import { AMINO_ACIDS } from '@ordaos/util/dist/constants';
import ProteinSequence from "../../dataviz/ProteinSequence/index.js";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import StructureRender from "./StructureRender.js";
const DEFAULT_PALETTE = {
    60: t.colorRed,
    80: t.colorYellow,
    90: t.colorLightGreen,
    100: t.colorOrdaosBlue,
};
function StructureViewer({ sequence, response, loadOpts, colors }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [display, setDisplayStyle] = useState('cartoon');
    const [hoveredResidue, setHoveredResidue] = useState(null);
    const [hoveredPSResidueIndex, setHoveredPSResidueIndex] = useState(null);
    const [view, setView] = useState(null);
    const [colorScheme, setColorScheme] = useState('Structure Confidence');
    const activePdbFile = response?.pdbFiles?.[activeIndex];
    const hydrophobicityScores = useMemo(() => {
        return sequence
            .split('')
            .map((res) => AMINO_ACIDS.find((a) => a.acid === res)?.hydrophobicity)
            .map(toHydrophobicityScore);
    }, [sequence]);
    if (!activePdbFile) {
        return null;
    }
    const { pdbFiles } = response;
    const hasStructureScores = activePdbFile.structureScorePerResidue?.length > 0;
    const hasSpatialAggregation = activePdbFile.spatialAggregationPropensity?.length > 0;
    const hasSpatialChargeMap = activePdbFile.spatialChargeMap?.length > 0;
    const showConfidenceLegend = colorScheme === 'Structure Confidence' ||
        colorScheme === 'Structural Discriminator';
    const showHydrophobicityLegend = colorScheme === 'Hydrophobicity';
    return (React.createElement("div", { className: cx(s, 'structure-wrapper') },
        React.createElement(ProteinSequence, { values: getConfidenceScores(activePdbFile), legend: false, colors: {
                ...getColorPalette(colorScheme, colors),
                selected: t.colorWhite,
            }, bordered: true, 
            // @ts-ignore
            onClickResidue: (i) => {
                setView({ position: i });
            }, onHoverResidue: setHoveredPSResidueIndex, activeIndex: !hoveredPSResidueIndex && hoveredResidue?.index }, sequence),
        React.createElement("div", { className: cx(s, 'structure') },
            React.createElement(Anchor, { href: activePdbFile.file, download: true, className: cx(s, 'structure-download') },
                React.createElement(Icons.Download, { filter: 'brightness(0)' })),
            React.createElement("div", { className: cx(s, 'structure-panel') },
                showConfidenceLegend && (React.createElement(ConfidenceLegend, { colors: getColorPalette(colorScheme, colors) })),
                showHydrophobicityLegend && React.createElement(HydrophobicityLegend, null),
                React.createElement("dl", { className: cx(s, 'structure-panel-data') },
                    React.createElement("dt", null, "pLDDT Confidence"),
                    React.createElement("dd", null, activePdbFile.pLDDTScore.toFixed(2)),
                    activePdbFile.structureScore && (React.createElement(React.Fragment, null,
                        React.createElement("dt", null, "Structure Score"),
                        React.createElement("dd", null, activePdbFile.structureScore.toFixed(2)))),
                    typeof activePdbFile.spatialAggregationPropensityScore ===
                        'number' && (React.createElement(React.Fragment, null,
                        React.createElement("dt", null, "Spatial Aggregation Propensity Score"),
                        React.createElement("dd", null, activePdbFile.spatialAggregationPropensityScore.toFixed(2)))),
                    typeof activePdbFile.spatialChargeMapScore ===
                        'number' && (React.createElement(React.Fragment, null,
                        React.createElement("dt", null, "Spatial Charge Map"),
                        React.createElement("dd", null, activePdbFile.spatialChargeMapScore.toFixed(2))))),
                React.createElement("div", { className: cx(s, 'structure-panel-controls') },
                    React.createElement(Button, { size: 'xs', onClick: async () => {
                            await setView(null);
                            setView({ position: 'center' });
                        } }, "Center"),
                    React.createElement("div", null,
                        React.createElement(SelectField, { label: 'Color Scheme', value: colorScheme, options: getColorSchemeOptions(), onChange: setColorScheme })),
                    React.createElement(SelectField, { label: 'Display Style', value: display, options: ['cartoon', 'ball+stick', 'surface'], onChange: (v) => {
                            if (v === 'surface') {
                                setColorScheme('Hydrophobicity');
                            }
                            setDisplayStyle(v);
                        } }))),
            React.createElement(Carousel, { infiniteLoop: false, showThumbs: false, showArrows: false, onChange: (index) => setActiveIndex(index), renderIndicator: (onClick, isSelected) => {
                    return (React.createElement(Button, { icon: true, onClick: onClick },
                        React.createElement(Icons.BulletPoint, { empty: !isSelected })));
                } }, pdbFiles?.map((pdb) => {
                return (React.createElement(StructureRender, { file: pdb.file, key: pdb.file, confidencePerResidue: getConfidenceScores(activePdbFile), display: display, onHover: setHoveredResidue, view: view, hoveredPSIndex: hoveredPSResidueIndex, colors: getColorPalette(colorScheme, colors), loadOpts: loadOpts }));
            })),
            hoveredResidue && (React.createElement("dl", { className: cx(s, 'structure-hover-tooltip') },
                React.createElement("dt", null, "Residue Name"),
                React.createElement("dt", null, "pLDDT Confidence"),
                React.createElement("dd", null, activePdbFile.pLDDTScorePerResidue[hoveredResidue.index]?.toFixed(2)),
                hasStructureScores && (React.createElement(React.Fragment, null,
                    React.createElement("dt", null, "Structure Score"),
                    React.createElement("dd", null, activePdbFile.structureScorePerResidue[hoveredResidue.index]?.toFixed(2)))),
                hasSpatialAggregation && (React.createElement(React.Fragment, null,
                    React.createElement("dt", null, "Spatial Aggregation Propensity"),
                    React.createElement("dd", null, activePdbFile.spatialAggregationPropensity[hoveredResidue.index]?.toFixed(2)))),
                hasSpatialChargeMap && (React.createElement(React.Fragment, null,
                    React.createElement("dt", null, "Spatial Charge Map"),
                    React.createElement("dd", null, activePdbFile.spatialChargeMap[hoveredResidue.index]?.toFixed(2)))),
                React.createElement("dt", null, "Hydrophobicity"),
                React.createElement("dd", null, AMINO_ACIDS.find((a) => {
                    return a.short === hoveredResidue.resname;
                })?.hydrophobicity),
                React.createElement("dt", null, "Atom Count"),
                React.createElement("dd", null, hoveredResidue.atomCount),
                React.createElement("dt", null, "Chain Name"),
                React.createElement("dd", null, hoveredResidue.chainname))))));
    function getColorSchemeOptions() {
        return [
            'Structure Confidence',
            hasStructureScores && 'Structural Discriminator',
            hasSpatialAggregation && 'Spatial Aggregation Propensity',
            hasSpatialChargeMap && 'Spatial Charge Map',
            'Hydrophobicity',
        ].filter((v) => v);
    }
    function getConfidenceScores(pdb) {
        switch (colorScheme) {
            case 'Structure Confidence':
                return pdb.pLDDTScorePerResidue;
            case 'Structural Discriminator':
                return pdb.structureScorePerResidue;
            case 'Spatial Aggregation Propensity':
                return pdb.spatialAggregationPropensity;
            case 'Spatial Charge Map':
                return pdb.spatialChargeMap;
            case 'Hydrophobicity':
                return hydrophobicityScores;
            default:
                return pdb.pLDDTScorePerResidue;
        }
    }
}
export default StructureViewer;
function HydrophobicityLegend() {
    return (React.createElement("legend", { className: cx(s, 'structure-panel-legend') },
        React.createElement("div", { className: cx(s, 'structure-panel-legend-title') }, "Hydrophobicity"),
        React.createElement("div", null,
            React.createElement(Icons.BulletPoint, { fill: t.colorOrdaosBlue }),
            ": hydrophilic"),
        React.createElement("div", null,
            React.createElement(Icons.BulletPoint, { fill: t.colorYellow }),
            ": neutral"),
        React.createElement("div", null,
            React.createElement(Icons.BulletPoint, { fill: t.colorRed }),
            ": hydrophobic")));
}
const CHARGE_MAP_PALETTE = {
    '-80': t.colorRed,
    '-60': t.colorYellow,
    '-40': t.colorDarkGreen,
    '-20': t.colorLightGreen,
    '0': t.colorOrdaosBlue,
};
const AGGREGATION_PROPENSITY_PALETTE = {
    0: t.colorRed,
    50: t.colorYellow,
    100: t.colorDarkGreen,
    150: t.colorLightGreen,
    200: t.colorOrdaosBlue,
};
function getColorPalette(scheme, customPalette) {
    if (customPalette) {
        return customPalette;
    }
    switch (scheme) {
        case 'Spatial Charge Map':
            return CHARGE_MAP_PALETTE;
        case 'Spatial Aggregation Propensity':
            return AGGREGATION_PROPENSITY_PALETTE;
        default:
            return DEFAULT_PALETTE;
    }
}
const ConfidenceLegend = ({ colors: palette }) => {
    const thresholds = Object.keys(palette).map(Number);
    const highToLow = thresholds.sort((a, b) => b - a);
    return (React.createElement("legend", { className: cx(s, 'structure-panel-legend') },
        React.createElement("div", { className: cx(s, 'structure-panel-legend-title') }, "Confidence"),
        highToLow.map((v, i) => {
            const next = thresholds[i + 1];
            const label = next ? `${next} - ${v}%` : `≤ ${v}%`;
            return (React.createElement("div", { key: label },
                React.createElement(Icons.BulletPoint, { fill: palette[v] }),
                ": ",
                label));
        })));
};
function toHydrophobicityScore(hydrophobicity) {
    switch (hydrophobicity) {
        case 'hydrophilic':
            return 90;
        case 'neutral':
            return 70;
        case 'hydrophobic':
            return 50;
        default:
            return 0;
    }
}
