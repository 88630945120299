import React from 'react';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { LegendOrdinal } from '@visx/legend';
import { Text } from '@visx/text';
import { object as o, array as a } from '@ordaos/util';
import { allByType } from 'react-children-by-type';
import BaseChart from "../BaseChart.js";
import cx from "../../lib/classnames.js";
import * as s from './StackedBarGraph.module.scss';
function StackedBarGraph({ items: itemsRaw, toLabel, children, ...opts }) {
    const bars = allByType(children, Bar).map(o.pluck('props'));
    return (React.createElement(BaseChart, { width: 600, marginLeft: 20, marginTop: 20, marginBottom: 60, data: Promise.resolve(itemsRaw), header: (items) => {
            const colorScale = scaleOrdinal({
                range: ['#096EFB', '#33cc99', '#3300CC', '#990099'],
                domain: bars.map(({ label }) => label),
            });
            return (React.createElement("div", { className: cx(s, 'stacked-bar-graph-legend') },
                React.createElement(LegendOrdinal, { scale: colorScale, direction: 'row' })));
        }, ...opts }, (items) => {
        const stacks = items.map((item) => ({
            label: o.value(toLabel)(item),
            bars: bars.map(({ value, label }) => ({
                label,
                height: o.value(value)(item),
            })),
        }));
        const totals = stacks.map((s) => s.bars.reduce((sum, b) => sum + b.height, 0));
        const min = opts.min ?? 0;
        const max = Math.max(...totals);
        const xScale = scaleBand({
            domain: a.range(0, items.length),
            range: [0, opts.width || 600],
            padding: 0.1,
        });
        const yScale = scaleLinear({
            domain: [min, max],
            range: [opts.height || 300, 0],
        });
        const colorScale = scaleOrdinal({
            range: ['#096EFB', '#33cc99', '#3300CC', '#990099'],
            domain: bars.map(({ label }) => label),
        });
        return (React.createElement(React.Fragment, null,
            React.createElement(Group, { transform: `translate(0, ${yScale(min)})` },
                React.createElement(AxisBottom, { scale: xScale, hideTicks: true, tickValues: items.map(() => null) })),
            React.createElement(AxisLeft, { scale: yScale, hideTicks: true }),
            stacks.map((stack, i) => {
                return (React.createElement(React.Fragment, { key: `stack-${i}` },
                    stack.bars.map(({ label, height }, j) => {
                        const btm = stack.bars
                            .slice(0, j)
                            .reduce((sum, b) => sum + b.height, min);
                        const top = btm + height;
                        return (React.createElement("rect", { key: `${label}-${i}`, x: xScale(i), y: yScale(top), width: 9, height: Math.abs(yScale(btm) - yScale(top)), fill: colorScale(label) }));
                    }),
                    React.createElement(Text, { x: xScale(i) + 5, y: opts.height + 15 || 315, key: `stack-label-${i}`, textAnchor: 'middle' }, stack.label)));
            })));
    }));
}
StackedBarGraph.Bar = Bar;
export default StackedBarGraph;
function Bar(props) {
    return null;
}
