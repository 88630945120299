import React from 'react';
import cx from "../../lib/classnames.js";
import * as s from './Anchor.module.scss';
const Anchor = ({ ...props }) => {
    const className = cx(s, 'anchor', undefined, props.className);
    if (props.onClick && props.href) {
        const { onClick, href, ...rest } = props;
        return (React.createElement("a", { ...rest, onClick: (e) => {
                e.preventDefault();
                props.onClick(e);
                window.location.href = props.href;
            }, className: className }));
    }
    else {
        return React.createElement("a", { ...props, className: className });
    }
};
export default Anchor;
