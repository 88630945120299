import React from 'react';
import cx from "../../lib/classnames.js";
import Select from "../Select/index.js";
import FormField from "../FormField/index.js";
import * as s from './SelectField.module.scss';
function SelectField(props) {
    const { options, value, naked, onChange, onBlur, toId, ...rest } = props;
    const values = Array.isArray(options)
        ? options.reduce((acc, v) => {
            return isOptgroup(v)
                ? acc.concat(...v.options.map(getValue))
                : acc.concat(getValue(v));
        }, [])
        : Object.values(options);
    const selectOptions = Array.isArray(options)
        ? options.map(toSelectItem)
        : Object.entries(options).map(toSelectItem);
    return (React.createElement(FormField, { ...rest, value: toStr(value), onChange: (i) => onChange?.(toVal(i)), onBlur: (i) => onBlur?.(toVal(i)), className: cx(s, 'select-field', { naked }) }, (thru) => React.createElement(Select, { ...thru, options: selectOptions })));
    function toStr(val) {
        const i = toId
            ? values.map(toId).indexOf(toId(val))
            : values.indexOf(val);
        return i >= 0 ? `${i}` : '';
    }
    function toVal(str) {
        return values[Number(str.replace(/^.* > /, ''))];
    }
    function toSelectItem(v) {
        return isOptgroup(v)
            ? { ...v, options: v.options.map(toSelectOpt) }
            : toSelectOpt(v);
    }
    function toSelectOpt(v) {
        return { label: getLabel(v), value: toStr(getValue(v)) };
    }
}
export default SelectField;
// HELPERS
function getLabel(o) {
    return isExplicitOpt(o) ? o.label : isTupleOpt(o) ? o[0] : `${o}`;
}
function getValue(o) {
    return isExplicitOpt(o) ? o.value : isTupleOpt(o) ? o[1] : o;
}
function isOptgroup(v) {
    return (typeof v === 'object' &&
        v.hasOwnProperty('group') &&
        v.hasOwnProperty('options'));
}
function isExplicitOpt(v) {
    return v.hasOwnProperty('label') && v.hasOwnProperty('value');
}
function isTupleOpt(v) {
    return Array.isArray(v) && v.length === 2;
}
