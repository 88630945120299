import React from 'react';
import { useForm } from "../Form/form-context.js";
import { string } from '@ordaos/util';
import useStatelyProp from "../../hooks/use-stately-prop.js";
import cx from "../../lib/classnames.js";
import Label from "../Label/index.js";
import * as Icons from "../Icon/index.js";
import Toggle from "../Toggle/index.js";
import * as s from './InputSection.module.scss';
function InputSection(props) {
    const [checked, setChecked] = useStatelyProp(props.toggleable !== 'unchecked');
    const { labelPlacement } = useForm();
    const showCaption = !['left', 'right'].includes(labelPlacement) && props.caption;
    const title = showCaption ? null : props.caption;
    const specificClass = props.label && `array-field--${string.dashify(props.label)}`;
    return (React.createElement("div", { className: cx(s, 'input-section', { [`label-${labelPlacement}`]: labelPlacement }, specificClass) },
        props.label && (React.createElement("span", { className: cx(s, 'input-section-label') },
            React.createElement(Label, { title: title }, props.label),
            props.info && React.createElement(Icons.Info, { text: props.info }),
            props.toggleable && (React.createElement(Toggle, { checked: checked, onClick: () => {
                    if (props.onToggle) {
                        props.onToggle(!checked);
                    }
                    return setChecked(!checked);
                } })))),
        showCaption && (React.createElement("span", { className: 'ord-u-type-preset-12' }, props.caption)),
        (!props.toggleable || (props.toggleable && checked)) &&
            props.children));
}
export default InputSection;
