import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { oneByType, withoutTypes } from 'react-children-by-type'

import Header from '../Header'
import Footer from '../Footer'
import CMS from '../../components/CMS'
import DangerousHTML from '../../components/DangerousHTML'

import './BasicPage.scss'

const BasicPage = ({ title, width, children }) => {
	const className = cx('main', {
		[`main--${width}`]: !!width,
	})
	const header = oneByType(children, BasicPage.Header) || <Header />
	const main = withoutTypes(children, BasicPage.Header, BasicPage.Links)
	const pageLinks = oneByType(children, BasicPage.Links)

	return (
		<React.Fragment>
			{header}
			<main className={className}>
				{title && (
					<DangerousHTML tag='h1' className='page-title'>
						{title}
					</DangerousHTML>
				)}
				{main}
			</main>
			{pageLinks}
			<Footer />
		</React.Fragment>
	)
}

BasicPage.propTypes = {
	children: PropTypes.node.isRequired,
}

export default BasicPage

BasicPage.Header = (props) => {
	return <Header {...props} />
}

BasicPage.Inset = (props) => {
	return <div {...props} className='inset' />
}

BasicPage.Links = (props) => {
	return <CMS.PageLinks {...props} />
}

BasicPage.RevisionDate = (props) => {
	return props.cms.data.includeRevisionDate ? (
		<p>Last Updated: {props.revisionDate}</p>
	) : null
}
