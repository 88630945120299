import React from 'react';
import cx from "../../lib/classnames.js";
import * as s from './Select.module.scss';
const Select = ({ options, placeholder, optional = false, size = 'sm', ...other }) => {
    const className = cx(s, 'select', { [size]: !!size });
    return (React.createElement("select", { ...other, defaultValue: other.value ?? '', className: className },
        placeholder !== undefined && (React.createElement("option", { value: '', disabled: !optional }, placeholder)),
        options.map((option) => {
            const key = JSON.stringify(option);
            if (isOptgroup(option)) {
                return (React.createElement("optgroup", { key: key, label: option.group }, option.options.map((sub) => (React.createElement(Option, { key: JSON.stringify(sub), option: sub, prefix: `${option.group} > ` })))));
            }
            return isOpt(option) ? (React.createElement(Option, { key: key, option: option })) : null;
        })));
};
const Option = ({ option, prefix = '', }) => {
    const label = typeof option === 'string' ? option : option.label;
    const value = typeof option === 'string' ? option : option.value;
    return (React.createElement("option", { key: value, value: `${prefix}${value}` }, label));
};
export default Select;
// HELPERS
export function isOpt(v) {
    return typeof v === 'string' || v.hasOwnProperty('label');
}
export function isOptgroup(v) {
    return (typeof v === 'object' &&
        v.hasOwnProperty('group') &&
        v.hasOwnProperty('options'));
}
