import React from 'react';
import cx from "../../lib/classnames.js";
import * as s from './Modal.module.scss';
function Modal({ open, onClose, data, contentAlign, children }) {
    const closed = !open;
    const containerClass = cx(s, 'modal-container', { closed });
    const overlayClass = cx(s, 'modal-overlay', { closed });
    const contentClass = cx(s, 'modal-content', {
        [contentAlign]: contentAlign,
    });
    // ===============================================================
    //  Temporarily disabled while sorting out gatsby upgrade for www
    // ===============================================================
    //
    // useWindowEvent<KeyboardEvent>(
    // 	'keyup',
    // 	(evt) => {
    // 		if (evt.code === 'Escape' && open) {
    // 			onClose()
    // 		}
    // 	},
    // 	null,
    // 	[open]
    // )
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: containerClass },
            React.createElement("div", { className: cx(s, 'close-button'), onClick: onClose },
                React.createElement("div", { className: cx(s, 'x-icon') }, "\u2715")),
            React.createElement("div", { className: contentClass }, open ? content() : null)),
        React.createElement("div", { className: overlayClass, onClick: onClose })));
    function content() {
        return typeof children === 'function' ? children(data) : children;
    }
}
export default Modal;
