import React from 'react';
import { scaleLinear, scaleOrdinal } from '@visx/scale';
import { Line, LinePath, Circle } from '@visx/shape';
import { Text } from '@visx/text';
import { allByType } from 'react-children-by-type';
import RadioField from "../../components/RadioField/index.js";
import useStatelyProp from "../../hooks/use-stately-prop.js";
import { object, array } from '@ordaos/util';
import * as t from '../../_tokens/index.theo.module';
import BaseChart from "../BaseChart.js";
function RadarGraph({ items: rawItems, children, numTicks = 5, radius = 150, ...opts }) {
    const rawAxes = allByType(children, Axis).map(object.pluck('props'));
    const [display, setDisplay] = useStatelyProp('individual');
    const radialScale = scaleLinear({
        range: [0, radius],
        domain: [0, 1],
    });
    const colorScale = scaleOrdinal({
        range: [t.colorOrdaosBlue, t.colorRed, t.colorYellow, t.colorDarkGreen],
    });
    const ticks = array.range(0, 1, 1 / numTicks);
    return (React.createElement(BaseChart, { marginTop: 20, marginBottom: 20, marginLeft: 100, marginRight: 100, data: Promise.resolve(rawItems), header: (data) => {
            if (data.length <= 1)
                return;
            return (React.createElement(RadioField, { toggle: true, value: display, options: [
                    { label: 'Individual', value: 'individual' },
                    { label: 'Average', value: 'avg' },
                ], onChange: setDisplay }));
        }, ...opts }, (items) => {
        const axes = items &&
            rawAxes.map((axis) => {
                const values = items.map((item) => item[axis.prop]);
                const min = axis.min ?? Math.min(...values);
                const max = axis.max ?? Math.max(...values);
                return {
                    ...axis,
                    max: max === min ? max + 1 : max,
                    min: min === max ? min - 1 : min,
                    avg: values.reduce((a, b) => a + b) / values.length,
                };
            });
        const avgCoordinates = axes?.map((axis, i) => coords(i, (axis.avg - axis.min) / (axis.max - axis.min)));
        return (React.createElement(React.Fragment, null,
            children,
            ticks?.map((t, i) => (React.createElement(Circle, { cx: radius, cy: radius, fill: 'none', stroke: 'grey', r: radialScale(t), key: `${t}-${radius}-${i}` }))),
            axes?.map((a, i) => {
                const [lineX, lineY] = coords(i, 1);
                const [labelX, labelY] = coords(i, 1.05);
                return (React.createElement(React.Fragment, { key: a.label },
                    React.createElement(Line, { x1: radius, y1: radius, x2: lineX, y2: lineY, stroke: 'black', key: `Line-${a.label}-${i}` }),
                    React.createElement(Text, { x: labelX, y: labelY, textAnchor: labelX < radius ? 'end' : 'start', key: `Text-${a.label}-${i}` }, a.label)));
            }),
            display === 'individual' &&
                items?.map((item, i) => {
                    const coordinates = axes?.map((a, j) => coords(j, (item[a.prop] -
                        a.min) /
                        (a.max - a.min)));
                    return (React.createElement(LinePath, { data: coordinates, x: (d) => d[0], y: (d) => d[1], fill: colorScale(i), opacity: 0.4, key: `${coordinates.join('')}-${i}` }));
                }),
            display === 'avg' && (React.createElement(LinePath, { data: avgCoordinates, x: (d) => d[0], y: (d) => d[1], fill: colorScale(0), opacity: 0.4, key: `${avgCoordinates.join('')}` }))));
        function coords(index, value) {
            const angle = Math.PI / 2 + (2 * Math.PI * index) / axes.length;
            const x = radius + Math.cos(angle) * radialScale(value);
            const y = radius - Math.sin(angle) * radialScale(value);
            return [x, y];
        }
    }));
}
RadarGraph.Axis = Axis;
export default RadarGraph;
function Axis(props) {
    return null;
}
