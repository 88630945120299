import React from 'react';
import { useForm } from "../Form/form-context.js";
import { array } from '@ordaos/util';
import cx from "../../lib/classnames.js";
import InputGroup from "../InputGroup/index.js";
import NumberField from "../NumberField/index.js";
import ObjectField from "../ObjectField/index.js";
import SelectField from "../SelectField/index.js";
import * as s from './MinMaxField.module.scss';
function MinMaxField(props) {
    const { labelPlacement } = useForm();
    return (React.createElement("div", { className: cx(s, 'minmax-field', {
            [`label-${labelPlacement}`]: labelPlacement,
        }) },
        React.createElement(ObjectField, { value: props.value, onChange: (v) => props.onChange?.(v), onBlur: (v) => props.onBlur?.(v) }, ({ handler }) => (React.createElement(InputGroup, { label: props.label }, props.range ? (React.createElement(React.Fragment, null,
            React.createElement(SelectField, { options: array.range(props.value?.max ?? props.range.max, props.range.min), placeholder: 'Min', ...handler('min') }),
            React.createElement(SelectField, { options: array.range(props.range.max, props.value?.min ?? props.range.min), placeholder: 'Max', ...handler('max') }))) : (React.createElement(React.Fragment, null,
            React.createElement(NumberField, { placeholder: 'Min', ...handler('min') }),
            React.createElement(NumberField, { placeholder: 'Max', ...handler('max') }))))))));
}
export default MinMaxField;
