import React from 'react';
import cx from "../../lib/classnames.js";
import AutocompleteField from "../AutocompleteField/index.js";
import TextField from "../TextField/index.js";
import * as Icon from "../Icon/index.js";
import * as s from './SearchField.module.scss';
function SearchField(props) {
    return (React.createElement("div", { className: cx(s, 'search-field') },
        React.createElement(Icon.Search, null),
        isAutocomplete(props) ? (React.createElement(AutocompleteField, { placeholder: 'Search', ...props })) : (React.createElement(TextField, { placeholder: 'Search', ...props }))));
}
export default SearchField;
function isAutocomplete(props) {
    return props.hasOwnProperty('items');
}
