import React, { Component } from 'react';
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: '',
        };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true, error: error.toString() };
    }
    render() {
        if (this.state.hasError) {
            if (this.props.output)
                return this.props.output(this.state.error);
            return (React.createElement(React.Fragment, null,
                React.createElement("h1", null, "Something went wrong."),
                React.createElement("p", null, this.props.message || this.state.error)));
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
