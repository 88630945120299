import React from 'react';
import { allByType } from 'react-children-by-type';
import cx from "../../lib/classnames.js";
import * as s from './Row.module.scss';
const Row = ({ children }) => {
    const items = allByType(children, Item);
    return (React.createElement("div", { className: cx(s, 'row') }, items.map(({ props: { children } }, i) => (React.createElement("div", { className: cx(s, 'row--item'), key: `row-item-${i}` }, children)))));
};
export default Row;
const Item = (Row.Item = (props) => {
    return null;
});
