import React from 'react';
import { useForm } from "../Form/form-context.js";
import cx from "../../lib/classnames.js";
import Label from "../Label/index.js";
import * as s from './InputGroup.module.scss';
const InputGroup = ({ label, children, ...props }) => {
    const labelPlacement = props.labelPlacement ?? useForm().labelPlacement;
    const className = cx(s, 'input-group', {
        [`label-${labelPlacement}`]: labelPlacement,
    });
    return (React.createElement("div", { ...props, className: className },
        label && React.createElement(Label, null, label),
        React.createElement("span", { className: cx(s, 'input-group--inputs') }, children)));
};
export default InputGroup;
