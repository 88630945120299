import React from 'react';
import cx from "../../lib/classnames.js";
import FormField from "../FormField/index.js";
import Label from "../Label/index.js";
import * as s from './RadioField.module.scss';
function isSimpleOpt(opt) {
    return !opt.hasOwnProperty('label');
}
function RadioField(props) {
    const { options, inline, disabled, onChange, value, toggle, ...rest } = props;
    const values = options.map((opt) => (isSimpleOpt(opt) ? opt : opt.value));
    const className = cx(s, 'radio-field', { inline, toggle });
    return (React.createElement(FormField, { ...rest, className: className, value: toStr(value), onChange: (i, e) => {
            if (onChange)
                onChange(toVal(i), e);
        } }, (thru) => (React.createElement(React.Fragment, null, options.map((opt) => {
        const optLabel = isSimpleOpt(opt)
            ? opt.toString()
            : opt.label;
        const optValue = isSimpleOpt(opt) ? opt : opt.value;
        const strValue = toStr(optValue);
        const id = `${thru.name}-${optValue}`;
        return (React.createElement(RadioButton, { key: id, id: id, name: thru.name, label: optLabel, value: strValue, checked: thru.value === strValue, disabled: !!disabled, onChange: thru.onChange }));
    })))));
    function toStr(val) {
        return values.indexOf(val).toString();
    }
    function toVal(str) {
        return values[Number(str)];
    }
}
export default RadioField;
function RadioButton({ disabled, ...props }) {
    const className = cx(s, 'radio-button', { disabled });
    return (React.createElement("div", { className: className },
        React.createElement("input", { ...props, type: 'radio', disabled: disabled }),
        React.createElement(Label, { htmlFor: props.id }, props.label)));
}
