import React, { createContext, useContext } from 'react';
const FormContext = createContext({
    labelPlacement: null,
});
export const FormProvider = ({ labelPlacement, children, }) => {
    return (React.createElement(FormContext.Provider, { value: { labelPlacement } }, children));
};
export function useForm() {
    return useContext(FormContext);
}
