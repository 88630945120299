import { useState, useEffect } from 'react';
import fastDeepEqual from 'fast-deep-equal';
function useStatelyProp(value, options = {
    prepare: (_) => _,
    preventUpdate: () => false,
    deepEqual: false,
}) {
    const prepare = options.prepare ?? ((_) => _);
    const preventUpdate = options.preventUpdate ?? (() => false);
    const comparable = prepare(value);
    const [stateValue, setStateValue] = useState(value);
    useEffect(() => {
        if (options.deepEqual) {
            if (!fastDeepEqual(comparable, prepare(stateValue)) &&
                !preventUpdate(value))
                setStateValue(value);
        }
        else {
            if (comparable !== prepare(stateValue) && !preventUpdate(value))
                setStateValue(value);
        }
    }, [comparable]);
    return [stateValue, setStateValue];
}
export default useStatelyProp;
