/* Caps the width of text passages to an optimal reading length */
/* Caps the width of the content to a maximum value so the page doesn't span the full width of the viewport on larger screens */
export const animEase = "ease-out";
export const animEaseSnap = "cubic-bezier(0, 0, 0.2, 1)";
export const animFadeLong = "0.4s";
export const animFadeQuick = "0.15s";
export const animMoveQuick = "0.3s";
export const borderRadius = "25px";
export const borderRadiusSemiSharp = "5px";
export const borderRadiusSharp = "0px";
export const borderWidth = "1px";
export const borderWidthLarge = "4px";
export const borderWidthMed = "2px";
export const borderWidthXl = "8px";
export const bpLarge = "60rem";
export const bpMed = "47rem";
export const bpSmall = "28rem";
export const bpSmall2 = "35rem";
export const bpXl = "70rem";
export const cardBoxShadow = "0 2px 3px rgba(0, 0, 0, 0.17), 0 2px 4px rgba(0, 0, 0, 0.14)";
export const cardBoxShadowHover = "0 7px 14px rgba(0, 0, 0, 0.29), 0 7px 7px rgba(0, 0, 0, 0.33)";
export const colorAccent1 = "hsl(0, 100%, 60%)";
export const colorAccent1H = "0";
export const colorAccent1Hex = "#Ff3333";
export const colorAccent1L = "60%";
export const colorAccent1S = "100%";
export const colorAccent2 = "hsl(300, 100%, 30%)";
export const colorAccent2H = "300";
export const colorAccent2Hex = "#990099";
export const colorAccent2L = "30%";
export const colorAccent2S = "100%";
export const colorAccent3 = "hsl(255, 100%, 40%)";
export const colorAccent3H = "255";
export const colorAccent3Hex = "#3300CC";
export const colorAccent3L = "40%";
export const colorAccent3S = "100%";
export const colorBackground = "hsl(0, 0%, 100%)";
export const colorBackgroundH = "0";
export const colorBackgroundHex = "#ffffff";
export const colorBackgroundL = "100%";
export const colorBackgroundS = "0%";
export const colorBlack = "hsl(0, 0%, 7%)";
export const colorBlackH = 0;
export const colorBlackHex = "#111111";
export const colorBlackL = "7%";
export const colorBlackS = "0%";
export const colorCharcoal = "hsl(0, 0%, 20%)";
export const colorCharcoalH = 0;
export const colorCharcoalHex = "#333333";
export const colorCharcoalL = "20%";
export const colorCharcoalS = "0%";
export const colorDarkBackground = "hsl(195, 13%, 94%)";
export const colorDarkBackgroundH = "195";
export const colorDarkBackgroundHex = "#eef1f2";
export const colorDarkBackgroundL = "94%";
export const colorDarkBackgroundS = "13%";
export const colorDarkGray = "hsl(224, 11%, 39%)";
export const colorDarkGrayH = 224;
export const colorDarkGrayHex = "#595f6f";
export const colorDarkGrayL = "39%";
export const colorDarkGrayS = "11%";
export const colorDarkGreen = "hsl(160, 100%, 30%)";
export const colorDarkGreenH = 160;
export const colorDarkGreenHex = "#009966";
export const colorDarkGreenL = "30%";
export const colorDarkGreenS = "100%";
export const colorDeepBlue = "hsl(241, 100%, 25%)";
export const colorDeepBlueH = 241;
export const colorDeepBlueHex = "#020082";
export const colorDeepBlueL = "25%";
export const colorDeepBlueS = "100%";
export const colorFuschia = "hsl(300, 100%, 30%)";
export const colorFuschiaH = 300;
export const colorFuschiaHex = "#990099";
export const colorFuschiaL = "30%";
export const colorFuschiaS = "100%";
export const colorGradient = "linear-gradient(to bottom, hsl(241, 100%, 25%), #010041)";
export const colorGradientInverse = "linear-gradient(to top, hsl(241, 100%, 25%), #010041)";
export const colorGray = "hsl(0, 0%, 27%)";
export const colorGrayH = 0;
export const colorGrayHex = "#444444";
export const colorGrayL = "27%";
export const colorGrayS = "0%";
export const colorHeading = "hsl(0, 0%, 7%)";
export const colorHeadingH = "0";
export const colorHeadingHex = "#111111";
export const colorHeadingL = "7%";
export const colorHeadingS = "0%";
export const colorIce = "hsl(195, 13%, 94%)";
export const colorIceH = 195;
export const colorIceHex = "#eef1f2";
export const colorIceL = "94%";
export const colorIceS = "13%";
export const colorInverse = "hsl(0, 0%, 100%)";
export const colorInverseH = "0";
export const colorInverseHex = "#ffffff";
export const colorInverseL = "100%";
export const colorInverseS = "0%";
export const colorLightGreen = "hsl(160, 60%, 50%)";
export const colorLightGreenH = 160;
export const colorLightGreenHex = "#33cc99";
export const colorLightGreenL = "50%";
export const colorLightGreenS = "60%";
export const colorMist = "hsl(198, 12%, 79%)";
export const colorMistH = 198;
export const colorMistHex = "#C4CDD1";
export const colorMistL = "79%";
export const colorMistS = "12%";
export const colorNeonBlue = "hsl(240, 100%, 50%)";
export const colorNeonBlueH = 240;
export const colorNeonBlueHex = "#0000ff";
export const colorNeonBlueL = "50%";
export const colorNeonBlueS = "100%";
export const colorOrange = "hsl(15, 100%, 60%)";
export const colorOrangeH = 15;
export const colorOrangeHex = "#ff6633";
export const colorOrangeL = "60%";
export const colorOrangeS = "100%";
export const colorOrdaosBlue = "hsl(215, 97%, 51%)";
export const colorOrdaosBlueH = 215;
export const colorOrdaosBlueHex = "#096EFB";
export const colorOrdaosBlueL = "51%";
export const colorOrdaosBlueS = "97%";
export const colorPrimary = "hsl(215, 97%, 51%)";
export const colorPrimaryH = "215";
export const colorPrimaryHex = "#096EFB";
export const colorPrimaryL = "51%";
export const colorPrimaryS = "97%";
export const colorPurple = "hsl(255, 100%, 40%)";
export const colorPurpleH = 255;
export const colorPurpleHex = "#3300CC";
export const colorPurpleL = "40%";
export const colorPurpleS = "100%";
export const colorRed = "hsl(0, 100%, 60%)";
export const colorRedH = 0;
export const colorRedHex = "#Ff3333";
export const colorRedL = "60%";
export const colorRedS = "100%";
export const colorSecondary = "hsl(241, 100%, 25%)";
export const colorSecondaryH = "241";
export const colorSecondaryHex = "#020082";
export const colorSecondaryL = "25%";
export const colorSecondaryS = "100%";
export const colorSilver = "hsl(200, 12%, 66%)";
export const colorSilverH = 200;
export const colorSilverHex = "#9DABB2";
export const colorSilverL = "66%";
export const colorSilverS = "12%";
export const colorText = "hsl(0, 0%, 7%)";
export const colorTextH = "0";
export const colorTextHex = "#111111";
export const colorTextL = "7%";
export const colorTextS = "0%";
export const colorWhite = "hsl(0, 0%, 100%)";
export const colorWhiteH = 0;
export const colorWhiteHex = "#ffffff";
export const colorWhiteL = "100%";
export const colorWhiteS = "0%";
export const colorYellow = "hsl(36, 100%, 50%)";
export const colorYellowH = 36;
export const colorYellowHex = "#ff9900";
export const colorYellowL = "50%";
export const colorYellowS = "100%";
export const fontHeadings = "Gilroy, sans-serif";
export const fontParagraph = "Avenir, sans-serif";
export const fontSizeLarge = "1.5rem";
export const fontSizeLarge2 = "2rem";
export const fontSizeMed = "1rem";
export const fontSizeMed2 = "1.25rem";
export const fontSizeSm = ".75rem";
export const fontSizeSm2 = ".875rem";
export const fontSizeXl = "2.5rem";
export const fontSizeXs = ".6875rem";
export const fontSizeXxs = ".625rem";
export const fontUi = "Avenir, sans-serif";
export const lLinelengthWidth = "36rem";
export const lMaxWidth = "70.9375rem";
export const marginLg = "2rem";
export const marginMd = "1rem";
export const marginSm = ".5rem";
export const paddingLg = "2rem";
export const paddingMd = "1rem";
export const paddingSm = ".5rem";
export const paddingXl = "3rem";
export const paddingXs = ".25rem";
