import * as xlsx from 'xlsx';
function excel(rows, cols, { title = 'Data Table Export', context = {} }) {
    const wb = xlsx.utils.book_new();
    // Title Sheet
    const titleData = [[title], [], ...Object.entries(context)];
    const titleWs = xlsx.utils.aoa_to_sheet(titleData);
    xlsx.utils.book_append_sheet(wb, titleWs, 'Title');
    let columnWidths = [];
    // Data Sheet
    const data = [
        cols.map((col) => col.heading),
        ...rows.map(({ item }, i) => cols.map(({ value, fmt }, j) => {
            const v = typeof value === 'function' ? value(item, i) : item[value];
            const t = getTypeOfCell(v, fmt);
            columnWidths[j] = Math.max(columnWidths[j] ?? 10, v?.length ?? 0);
            return {
                t,
                v: v ?? '',
                z: fmt,
            };
        })),
    ];
    const dataWs = xlsx.utils.aoa_to_sheet(data);
    dataWs['!cols'] = cols.map((c, i) => ({
        ...c,
        hidden: false,
        wch: columnWidths[i],
    }));
    dataWs['!rows'] = [{}, ...rows]; // Extra {} to account for heading row
    xlsx.utils.book_append_sheet(wb, dataWs, 'Data');
    xlsx.writeFile(wb, `${title}.xlsx`, { compression: true });
}
function getTypeOfCell(v, fmt) {
    if (v instanceof Date) {
        return 'd';
    }
    else if (typeof v === 'number') {
        return 'n';
    }
    else if (typeof v === 'string') {
        if (!!fmt)
            return 'n';
        return 's';
    }
    else if (typeof v === 'boolean') {
        return 'b';
    }
    return 's';
}
export default excel;
