import React from 'react';
import { object, string } from '@ordaos/util';
import NumberField from "../NumberField/index.js";
import SelectField from "../SelectField/index.js";
import TextField from "../TextField/index.js";
function isRenderable(filterable) {
    return typeof filterable === 'object' && 'render' in filterable;
}
function isFilterableConfig(filterable) {
    return typeof filterable === 'object' && !('render' in filterable);
}
export function isKeyColumn(col) {
    return typeof col.value === 'string';
}
export function isMapColumn(col) {
    return typeof col.value === 'function';
}
export function isMultiviewableColumn(col) {
    return !!col.hasOwnProperty('views');
}
function Column(props) {
    return null;
}
export default Column;
// HELPERS
export function heading(col) {
    return 'heading' in col ? col.heading : string.titleify(String(col.value));
}
export function filterable(col) {
    if (isRenderable(col.filterable)) {
        return col.filterable;
    }
    const { type, operators, onFilter, onRemoveFilter } = isFilterableConfig(col.filterable)
        ? col.filterable
        : {
            type: col.filterable,
            operators: undefined,
            onFilter: undefined,
            onRemoveFilter: undefined,
        };
    switch (type) {
        case 'num':
            return numberFilter(operators, onFilter, onRemoveFilter);
        case 'str':
        default:
            return stringFilter(operators, onFilter, onRemoveFilter);
    }
}
export function stringFilter(operators, onFilter, onRemoveFilter) {
    return {
        default: () => ({ by: 'eq', expected: null }),
        render: (props) => (React.createElement(StringFilter, { ...props, operators: operators })),
        onFilter: onFilter ?? ((f) => f),
        onRemoveFilter: onRemoveFilter ?? ((f) => f),
    };
}
export function numberFilter(operators, onFilter, onRemoveFilter) {
    return {
        default: () => ({ by: 'eq', expected: null }),
        render: (props) => (React.createElement(NumberFilter, { ...props, operators: operators })),
        onFilter: onFilter ?? ((f) => f),
        onRemoveFilter: onRemoveFilter ?? ((f) => f),
    };
}
function StringFilter({ handler, operators }) {
    const opts = {
        is: 'eq',
        'is not': 'ne',
        contains: 'contains',
        'is in': 'in',
        'loose matches': 'fuzzy',
        'starts with': 'starts',
        'ends with': 'ends',
    };
    const ops = operators ?? Object.values(opts);
    return (
    // prettier-ignore
    React.createElement(React.Fragment, null,
        React.createElement(SelectField, { ...handler('by'), size: 'xs', options: object.pickValues(opts, ...ops) }),
        React.createElement(TextField, { ...handler('expected'), size: 'xs', nullable: true, onChange: null })));
}
function NumberFilter({ handler, operators }) {
    const opts = {
        '=': 'eq',
        '≠': 'ne',
        '>': 'gt',
        '>=': 'gte',
        '<': 'lt',
        '<=': 'lte',
    };
    const ops = operators ?? Object.values(opts);
    return (
    // prettier-ignore
    React.createElement(React.Fragment, null,
        React.createElement(SelectField, { ...handler('by'), size: 'xs', options: object.pickValues(opts, ...ops) }),
        React.createElement(NumberField, { ...handler('expected'), size: 'xs', nullable: true, onChange: null })));
}
