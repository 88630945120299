import React, { useContext, createContext } from 'react';
import { string } from '@ordaos/util';
import cx from "../../lib/classnames.js";
import * as s from './JSONDisplay.module.scss';
const ClassContext = createContext({
    listClass: cx(s, 'json-display'),
});
function JSONDisplay({ obj, flex = false }) {
    const { listClass } = useContext(ClassContext);
    if (Object(obj) !== obj) {
        return (React.createElement("pre", { className: cx(s, 'json-display-item-value') }, JSON.stringify(obj)));
    }
    if (Array.isArray(obj)) {
        return (React.createElement(ClassContext.Provider, { value: { listClass: cx(s, 'json-display-inner-list') } },
            React.createElement("ul", { className: listClass }, obj.map((v, i) => (React.createElement("li", { className: cx(s, 'json-display-array-item'), key: i },
                React.createElement(JSONDisplay, { obj: v, flex: flex })))))));
    }
    const entries = Object.entries(obj)
        .sort(([_, valueA], [__, valueB]) => {
        if (valueA === null && valueB !== null) {
            return 1;
        }
        if (valueA !== null && valueB === null) {
            return -1;
        }
        return 0;
    })
        .filter(([k]) => !k.startsWith('__'));
    const itemClass = flex ? 'json-display-item-flex' : 'json-display-item';
    return (React.createElement(ClassContext.Provider, { value: { listClass: cx(s, 'json-display-inner-list') } },
        React.createElement("ul", { className: listClass }, entries.map(([k, v]) => {
            if (k.startsWith('_type')) {
                return (React.createElement("li", { className: cx(s, 'json-display-item-heading'), key: k },
                    React.createElement("h4", null, string.titleify(k))));
            }
            else {
                return (React.createElement("li", { className: cx(s, itemClass), key: k },
                    React.createElement("h4", null, string.titleify(k)),
                    React.createElement(JSONDisplay, { obj: v, flex: flex })));
            }
        }))));
}
export default JSONDisplay;
