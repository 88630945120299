import React from 'react';
import cx from "../../lib/classnames.js";
import FormField from "../FormField/index.js";
import Input from "../Input/index.js";
import * as s from './RangeField.module.scss';
function RangeField(props) {
    const { options, value, onChange, onBlur, ...rest } = props;
    return (React.createElement(FormField, { ...rest, className: cx(s, 'range-field'), value: toStr(value), onChange: (i, e) => {
            if (onChange)
                onChange(toVal(i), e);
        }, onBlur: (i, e) => {
            if (onBlur)
                onBlur(toVal(i), e);
        } }, (thru) => (React.createElement(Input, { ...thru, type: 'range', min: 0, max: options.length - 1, step: 1 }))));
    function toStr(val) {
        return options.indexOf(val).toString();
    }
    function toVal(str) {
        return options[Number(str)];
    }
}
export default RangeField;
