import React, { useState } from 'react';
import cx from "../../lib/classnames.js";
import useStatelyProp from "../../hooks/use-stately-prop.js";
import { Loading } from "../Icon/index.js";
import TextField from "../TextField/index.js";
import * as s from './AutocompleteField.module.scss';
const LOADING = Symbol('LOADING');
function AutocompleteField({ items, start = 3, limit = 10, value, itemToInputValue = (v) => v.toString(), onChange, ...other }) {
    const [matches, setMatches] = useState([]);
    const [inputValue, setInputValue] = useStatelyProp(value);
    const showResults = matches === LOADING || !!matches.length;
    return (React.createElement("div", { className: cx(s, 'autocomplete-field') },
        React.createElement(TextField, { ...other, value: inputValue, onChange: onFilterItems }),
        showResults && (React.createElement("ul", { className: cx(s, 'autocomplete-field--results') }, matches === LOADING ? (React.createElement("li", null,
            React.createElement(Loading, null))) : (matches.slice(0, limit).map((item) => (React.createElement("li", { key: itemToInputValue(item), onClick: () => onSelectItem(item) }, itemToInputValue(item)))))))));
    function onSelectItem(item) {
        setInputValue(itemToInputValue(item));
        if (onChange) {
            onChange(item);
        }
    }
    function onFilterItems(filterValue) {
        if (filterValue.length >= start) {
            const results = typeof items === 'function'
                ? items(filterValue)
                : items.filter(caseInsensitive(filterValue));
            if (isPromise(results)) {
                setMatches
                    .call(null, LOADING)(
                // for some reason, when i just call the function, it says it's not callable...
                results)
                    .then(setMatches);
            }
            else {
                setMatches(results);
            }
        }
        else {
            setMatches([]);
        }
        function caseInsensitive(test) {
            const lTest = test.toLowerCase();
            return (item) => !test || itemToInputValue(item).toLowerCase().includes(lTest);
        }
    }
}
export default AutocompleteField;
function isPromise(v) {
    return typeof v.then === 'function';
}
