import { useState } from 'react';
function useModal(opts) {
    const isSimple = typeof opts === 'boolean';
    const open = isSimple ? opts : !!opts?.data;
    const data = isSimple ? undefined : opts?.data;
    const [state, set] = useState({ open, data });
    return { open: state.open, data: state.data, onOpen, onClose };
    function onOpen(data) {
        set({ open: true, data });
        if (!isSimple) {
            return opts?.onOpen?.(data);
        }
        return;
    }
    function onClose() {
        set({ open: false });
        if (!isSimple) {
            return opts?.onClose?.(state.data);
        }
        return;
    }
}
export default useModal;
