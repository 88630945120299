import React from 'react';
import cx from "../../lib/classnames.js";
import { allByType } from 'react-children-by-type';
import StateContainer from "../StateContainer/index.js";
import * as s from './Card.module.scss';
const Card = ({ title, subtitle, highlight, onClick, children, actions, }) => {
    const cells = allByType(children, Cell);
    const splitBottomRadius = !cells[cells.length - 1]?.props.block;
    const className = cx(s, 'card', {
        split: splitBottomRadius,
        clickable: !!onClick,
    });
    return (React.createElement("div", { className: className, onClick: onClick },
        title && (React.createElement(Cell, { actions: actions, align: 'left', title: title, subtitle: subtitle, block: true })),
        cells.map((cell, i) => {
            return React.cloneElement(cell, { highlight, ...cell.props });
        })));
};
export default Card;
const Cell = (Card.Cell = (props) => {
    const { title, subtitle, value, block, children, align = 'center', inline, highlight, inactive, error, onClick, actions, } = props;
    const content = children || value;
    const hasTitle = title || subtitle;
    return (React.createElement("div", { className: cx(s, 'card--cell', {
            block,
            inline,
            [align]: align,
            highlight,
            inactive,
            error,
        }), onClick: onClick
            ? (e) => {
                e.stopPropagation();
                onClick();
            }
            : undefined },
        React.createElement("div", { className: cx(s, 'card--cell-header') },
            hasTitle && (React.createElement("span", { className: cx(s, 'card--cell-header-titles') },
                title && (React.createElement("h2", { className: cx(s, 'card--cell-header-titles-main') }, title)),
                subtitle && (React.createElement("h3", { className: cx(s, 'card--cell-header-titles-sub') }, subtitle)))),
            actions && (React.createElement("ul", { className: cx(s, 'card--cell-actions') }, actions))),
        content && (React.createElement(StateContainer, { state: getState(), className: cx(s, 'card--cell-content') }, content))));
    function getState() {
        switch (true) {
            case error:
                return 'failed';
            case highlight:
                return 'success';
            case inactive:
                return 'inactive';
            default:
                return null;
        }
    }
});
