"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cmdJ = exports.capitalize = exports.titleify = exports.snakeCase = exports.camelCase = exports.dashify = exports.startCase = void 0;
function startCase(str) {
    return str
        .toLowerCase()
        .replace(/(^|\s)(\w)/g, (s) => s.toUpperCase());
}
exports.startCase = startCase;
function dashify(str) {
    return str.replace(/\W+/g, '-').toLowerCase();
}
exports.dashify = dashify;
function camelCase(str) {
    const cleaned = str.replace(/[-_\s]+(\w)/gi, (str) => str.slice(-1).toUpperCase());
    return cleaned[0].toLowerCase() + cleaned.slice(1);
}
exports.camelCase = camelCase;
function snakeCase(str) {
    return str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((word) => word.toLowerCase())
        .join('_');
}
exports.snakeCase = snakeCase;
function titleify(str) {
    const words = str.replace(/((?<=\p{Ll})(?=\p{Lu})|(?<=\p{Lu})(?=\p{Lu}\p{Ll})|_)/gu, ' ');
    return startCase(words);
}
exports.titleify = titleify;
function capitalize(str) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}
exports.capitalize = capitalize;
function cmdJ(strs, ...args) {
    return strs.reduce((acc, str, i) => acc + str.replace(/^(\t|\s)*/, ' ') + (args[i] ?? ''), '');
}
exports.cmdJ = cmdJ;
