"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debounce = void 0;
function debounce(fn, n) {
    let timeout;
    let promise;
    return function (...args) {
        const current = new Promise((res) => {
            if (timeout)
                clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                res(fn.apply(this, args));
            }, n);
        });
        if (!promise) {
            promise = current;
            return promise;
        }
        else {
            return promise.then(() => current);
        }
    };
}
exports.debounce = debounce;
