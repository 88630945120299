import React from 'react';
import cx from "../../lib/classnames.js";
import * as s from './Input.module.scss';
import { diffChars } from 'diff';
const Input = (props) => {
    const { size = 'sm', accept, transform, ...rest } = props;
    const className = cx(s, 'input', { [size]: size });
    return (React.createElement("input", { ...rest, className: className, onChange: (e) => {
            const newValue = e.target.value;
            if (accept && !checkIsAcceptable(newValue))
                return;
            const transformedValue = transform
                ? transform(newValue)
                : newValue;
            return props.onChange?.({
                ...e,
                target: {
                    ...e.target,
                    value: transformedValue,
                },
            });
        } }));
    function checkIsAcceptable(newValue) {
        if (!newValue)
            return true;
        const regex = RegExp(`^${accept}+$`);
        return diffChars(props.value, newValue).every(({ added, value }) => !added || regex.test(value));
    }
};
export default Input;
