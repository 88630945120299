import React, { createContext, useContext } from 'react';
import useStatelyProp from "../../hooks/use-stately-prop.js";
import { oneByType, allByType } from 'react-children-by-type';
import { object as o, constants } from '@ordaos/util';
import Button from "../Button/index.js";
import ProteinSequence from "../../dataviz/ProteinSequence/ProteinSequence.js";
import Tabs from "../Tabs/index.js";
import Modal from "../Modal/index.js";
import StructureViewer from "../StructureViewer/index.js";
import cx from "../../lib/classnames.js";
import * as s from './Protein.module.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// ---------------------------------- CONTEXT ----------------------------------
const ProteinContext = createContext({
    sequence: null,
    onRequestStructure: () => { },
});
const Protein = (props) => {
    const [isModalOpen, setIsModalOpen] = useStatelyProp(false);
    const { children, ...protein } = props;
    const passedModal = oneByType(children, Protein.Modal);
    const modal = passedModal ? (React.cloneElement(passedModal, {
        open: isModalOpen,
        onClose: onModalClose,
    })) : (React.createElement(ProteinModal, { open: isModalOpen, onClose: onModalClose }));
    const hydrophobicAcids = constants.AMINO_ACIDS.filter((aa) => aa.hydrophobicity === 'hydrophobic').map(o.pluck('acid'));
    const hydrophobicGroups = protein.sequence
        .split('')
        .map((c, idx) => (hydrophobicAcids.includes(c) ? [idx] : null))
        .filter((val) => !!val);
    return (React.createElement(ProteinContext.Provider, { value: protein },
        React.createElement("div", { className: cx(s, 'protein') },
            React.createElement(Button, { icon: true, onClick: onModalOpen },
                React.createElement(ProteinSequence, { values: props.normalizeMax
                        ? protein.values.map((v) => v / props.normalizeMax)
                        : protein.values, colors: {
                        ...protein.colors,
                        base: protein.color,
                    }, subgroups: hydrophobicGroups, legend: props.legendConfig ?? false }, protein.sequence)),
            modal)));
    function onModalOpen() {
        setIsModalOpen(true);
        return passedModal?.props?.onOpen?.();
    }
    function onModalClose() {
        setIsModalOpen(false);
        return passedModal?.props?.onClose?.();
    }
};
export default Protein;
const ProteinModal = (Protein.Modal = ({ open, onOpen, onClose, onRequestStructure, children, }) => {
    // eslint-disable-next-line
    const protein = useContext(ProteinContext);
    const tabs = allByType(children, Protein.Modal.Tab);
    const includeStructure = protein.structure || onRequestStructure;
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: open, onClose: onClose },
            React.createElement(Tabs, null,
                protein.ss3 && (React.createElement(Tabs.Tab, { title: 'Secondary Structure' },
                    React.createElement(SecondaryStructure, { ...protein }))),
                includeStructure && (React.createElement(Tabs.Tab, { title: 'Structure' }, protein.structure ? (React.createElement(StructureViewer, { response: protein.structure.response, sequence: protein.sequence })) : (React.createElement(Button, { onClick: onRequestStructure }, "Request Structure Data")))),
                tabs))));
});
Protein.Modal.Tab = Tabs.Tab;
const SecondaryStructure = (Protein.SecondaryStructure = ({ sequence, ss3, ss3ConfidencePerAA, ss3ConfidencePerAAGranular, wrappable = false, }) => {
    const confidence = ss3ConfidencePerAA?.map((c) => [c]) ?? ss3ConfidencePerAAGranular;
    return (React.createElement("div", { className: cx(s, 'secondary-structure') },
        !wrappable && (React.createElement("div", { className: cx(s, 'secondary-structure-scroll') },
            React.createElement("span", { className: cx(s, 'secondary-structure-labels') },
                React.createElement("div", null, "Protein:"),
                React.createElement("div", null, "Secondary:"),
                React.createElement("div", null, "Confidence:")),
            React.createElement("span", { className: cx(s, 'secondary-structure-values') },
                React.createElement("div", null, sequence),
                React.createElement("div", null, ss3),
                React.createElement("div", null, confidence.map((c) => Math.floor(Math.max(...c) * 10)))))),
        wrappable && (React.createElement(React.Fragment, null,
            React.createElement("span", { className: cx(s, 'secondary-structure-labels') },
                React.createElement("div", null, "Protein:"),
                React.createElement("div", null, "Secondary:"),
                React.createElement("div", null, "Confidence:")),
            confidence.map((c, i) => {
                return (React.createElement("div", { className: cx(s, 'secondary-structure-column'), key: `${c.join('')}-${i}` },
                    React.createElement("div", null, sequence.charAt(i)),
                    React.createElement("div", null, ss3.charAt(i)),
                    React.createElement("div", null, Math.floor(Math.max(...c) * 10))));
            }))),
        React.createElement(Protein.SecondaryStructure.Total, { ss3: ss3 })));
});
const Total = (Protein.SecondaryStructure.Total = ({ ss3, preface = 'Overall Score: ', }) => {
    return (React.createElement("div", { className: cx(s, 'secondary-structure-overall') },
        preface,
        [total('C'), total('H'), total('E')].join(', ')));
    function total(char) {
        const count = ss3.split(char).length - 1;
        const percentage = Math.floor((count / ss3.length) * 100);
        return `${percentage}% ${char}`;
    }
});
Protein.SecondaryStructure.Total = Total;
