import useStatelyProp from "./use-stately-prop.js";
function useHandler(opts) {
    const [value, setValue] = useStatelyProp(opts.value ?? null);
    return {
        value,
        onChange,
        onBlur,
        handle(prop) {
            return {
                value: value?.[prop],
                onChange: (next) => {
                    onChange({ ...value, [prop]: next });
                },
                onBlur: (next) => {
                    onBlur({ ...value, [prop]: next });
                },
            };
        },
    };
    function onChange(next) {
        setValue(next);
        return opts.onChange?.(next);
    }
    function onBlur(next) {
        setValue(next);
        return opts.onBlur?.(next);
    }
}
export default useHandler;
