import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { RichText, Button } from '@ordaos/nucleus'
import DangerousHTML from '../DangerousHTML'
import cx from 'classnames'

import './CMS.scss'

export const Description = ({ cms, ...rest }) => {
	return (
		<div className='ord-cms-description'>
			<DangerousHTML tag='p' {...rest}>
				{cms.data.description}
			</DangerousHTML>
		</div>
	)
}

export const Body = ({ cms, ...rest }) => {
	return (
		<div className='ord-cms-body'>
			<RichText>
				<DangerousHTML {...rest}>{cms.body}</DangerousHTML>
			</RichText>
		</div>
	)
}

export const Image = ({ image, ...rest }) => {
	if (!image) return null
	return <Img fluid={image.fluid} />
}

export const PageLinks = ({ cms, ...rest }) => {
	if (!cms) return null
	const { pageLinks } = cms.data

	return (
		<div className={cx('ord-cms-links')}>
			{pageLinks.map((page) => {
				return (
					<div className={cx('ord-cms-links-page')}>
						<RichText inverted>
							<h2>{page.title}</h2>
							<div
								className={cx('ord-cms-links-page-description')}
							>
								{page.description}
							</div>
							<Button size='xs' primary onClick={page.link}>
								{page.linkText}
							</Button>
						</RichText>
					</div>
				)
			})}
		</div>
	)
}

export default {
	title: ({ cms }) => cms.data.title,
	subtitle: ({ cms }) => cms.data.subtitle,
	hero: ({ cms }) => cms.data.hero,
	Description,
	Body,
	PageLinks,
	Image,
}

export const query = graphql`
	fragment CMSFragment on File {
		revisionDate: changeTime(formatString: "MMMM D, YYYY")
		cms: childMarkdownRemark {
			body: html
			data: frontmatter {
				title
				subtitle
				hero
				description
				includeRevisionDate
				pageLinks {
					title
					description
					link
					linkText
				}
			}
		}
	}

	fragment CMSImageFragment on File {
		image: childImageSharp {
			fluid {
				...GatsbyImageSharpFluid
			}
		}
	}
`
