import React from 'react';
import cx from "../../lib/classnames.js";
import useStatelyProp from "../../hooks/use-stately-prop.js";
import Checkbox from "../Checkbox/index.js";
import FormField from "../FormField/index.js";
import * as s from './CheckboxField.module.scss';
const CheckboxField = (props) => {
    const { value, ...rest } = props;
    const [checked, setChecked] = useStatelyProp(value ?? false);
    return (React.createElement(FormField, { ...rest, className: cx(s, 'checkbox-field'), onChange: (_, e) => {
            setChecked(!checked);
            if (props.onChange)
                props.onChange(!checked, e);
        }, onBlur: (_, e) => {
            if (props.onBlur)
                props.onBlur(checked, e);
        } }, (thru) => (React.createElement(Checkbox, { ...thru, value: String(checked), checked: checked }))));
};
export default CheckboxField;
