import React from 'react';
import cx from "../../lib/classnames.js";
import Button from "../Button/index.js";
import SelectField from "../SelectField/index.js";
import * as Icons from "../Icon/index.js";
import * as s from './Table.module.scss';
function Heading(props) {
    const { sortable, filterable, collapsible, html, ...col } = props;
    const { sorted, onSort } = sortable ?? {};
    const { collapsed, onToggle } = collapsible ?? {};
    const label = !!html?.views?.length ? (React.createElement(SelectField, { options: html.views.map((v) => ({
            label: `${col.heading} (${v})`,
            value: v,
        })), value: html.view, onChange: html.onChangeView, size: 'xs' })) : (React.createElement("span", { title: col.title }, col.heading));
    const className = cx(s, 'table--header-cell', { collapsed });
    const infoText = typeof col.info === 'string'
        ? col.info
        : isInfoObject(col.info)
            ? col.info.text
            : null;
    const infoModal = isInfoObject(col.info)
        ? col.info.modal
        : typeof col.info === 'string'
            ? null
            : col.info;
    return (React.createElement("th", { className: className },
        React.createElement("span", { className: cx(s, 'table--header-cell--value') },
            collapsed && (React.createElement(Button, { icon: true, size: 'xs', onClick: onToggle },
                React.createElement(Icons.Expand, null))),
            !collapsed && (React.createElement(React.Fragment, null,
                label,
                onSort && (React.createElement(Button, { icon: true, size: 'xs', onClick: onSort }, sorted ? (React.createElement(Icons.Arrow, { dir: sorted === 'asc' ? 'down' : 'up' })) : (React.createElement(Icons.DoubleArrow, null)))),
                filterable && (React.createElement(Button, { icon: true, size: 'xs', onClick: filterable.onFilter, disabled: filterable.disabled },
                    React.createElement(Icons.Filter, null))),
                collapsible && (React.createElement(Button, { icon: true, size: 'xs', onClick: onToggle },
                    React.createElement(Icons.Collapse, null))),
                col.info && (React.createElement(Icons.Info, { text: infoText }, infoModal)))))));
}
export default Heading;
function isInfoObject(info) {
    return (!!info && typeof info === 'object' && 'text' in info && 'modal' in info);
}
