import { array, string, promise as p } from '@ordaos/util';
import zip from "./zip.js";
async function download(name, source) {
    // Simple blob download
    if (source instanceof Blob) {
        return downloadFile(name, source);
    }
    // Simple string download
    if (typeof source === 'string') {
        return downloadFile(name, new Blob([source]));
    }
    // Single URL download
    if (typeof source === 'object' && 'url' in source) {
        return downloadFile(name, await fetchFile(source.url, name));
    }
    // Multi URL download
    if (typeof source === 'object' && 'urls' in source) {
        const shouldZip = source.zipped ?? true;
        const results = await Promise.allSettled(Object.entries(source.urls).map(async ([name, url]) => ({
            name,
            url,
            data: await fetchFile(url, name),
        })));
        const [files, failures] = array.split(results, p.isFulfilled);
        failures.forEach((f) => {
            const realIndex = results.indexOf(f);
            return source.onFail?.(f.reason, realIndex);
        });
        if (shouldZip) {
            const zipped = await zip({
                'manifest.txt': string.cmdJ `
                    Downloaded (${files.length} files)
                    ${files.map((f) => `  - ${f.value.name} (${f.value.url})\n`)}
    
                    Failed (${failures.length} files)
                    ${failures.map((f) => `  - ${f.reason.name} (${f.reason.url})\n`)}
                `,
                ...array.toRecord(files, (f) => f.value.name, (f) => f.value.data),
            });
            return downloadFile(name, zipped);
        }
        else {
            return files.forEach((f) => downloadFile(f.value.name, f.value.data));
        }
    }
    // Arbitrary JSON download
    if (typeof source === 'object' && 'json' in source) {
        return downloadFile(name, new Blob([JSON.stringify(source.json)]));
    }
    // Fallback to stringified content
    downloadFile(name, new Blob([JSON.stringify(source)]));
}
export default download;
export function downloadFile(name, data) {
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(data);
    downloadLink.download = name;
    downloadLink.click();
}
// HELPERS
async function fetchFile(url, name) {
    const res = await fetch(url.href);
    if (!res.ok)
        throw new DownloadError(url.href, res, name);
    return await res.blob();
}
export class DownloadError extends Error {
    filename;
    url;
    response;
    constructor(url, response, name) {
        super(`Failed to download ${url} with status ${response.status}`);
        this.filename = name ?? url;
        this.url = url;
        this.response = response;
    }
}
