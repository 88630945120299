import React, { useState } from 'react';
import { useClient } from 'urql';
import Table, { getColumns } from "./Table.js";
import { heading } from "./Column.js";
function QueryTable({ query, title, fakePagination, variables, connection, columns, refreshOn = [], children, ...props }) {
    columns = getColumns({ columns, children });
    const client = useClient();
    const [tableTitle, setTableTitle] = useState(!!title ? (typeof title === 'function' ? '' : title) : undefined);
    return (React.createElement(Table, { ...props, title: tableTitle, searchable: false, items: [
            async (original) => {
                const mapped = {
                    ...original,
                    ...(fakePagination && { limit: original.limit + 1 }),
                    filters: filters(original.filters),
                    sort: sort(original.sort),
                };
                const vars = variables?.(mapped, original) ?? mapped;
                const { data } = await runQuery(client, query, vars);
                const cxnRes = connection(data);
                if (typeof title === 'function')
                    setTableTitle(title(cxnRes));
                const items = cxnRes.edges.map(({ node }) => node);
                return fakePagination
                    ? {
                        items: items.length > original.limit
                            ? items.slice(0, -1)
                            : items,
                        total: cxnRes.edges.length + original.offset,
                    }
                    : {
                        items,
                        total: cxnRes.pageInfo.totalCount,
                    };
            },
            refreshOn,
        ], columns: columns.map(toBaseColumn) }));
    function filters(filters) {
        return filters.reduce((acc, f) => {
            if (f.column === 'any' || f.expected == null)
                return acc;
            const field = filterField(findColumn(f.column));
            return {
                ...acc,
                [field]: {
                    ...acc[field],
                    [f.by]: f.expected,
                },
            };
        }, {});
    }
    function sort(sort) {
        if (!sort)
            return null;
        const { column, direction } = sort;
        return {
            field: sortField(findColumn(column)),
            order: direction.toUpperCase(),
        };
    }
    function findColumn(col) {
        // @ts-ignore TODO figure out why heading optional / required is a problem
        return columns.find((c) => heading(toBaseColumn(c)) === col.heading);
    }
}
export default QueryTable;
QueryTable.Column = GQLColumn;
function GQLColumn(props) {
    return null;
}
function toBaseColumn(col) {
    return {
        ...col,
        sortable: typeof col.sortable === 'object' && 'onSort' in col.sortable
            ? col.sortable
            : !!col.sortable,
    };
}
function sortField(col) {
    if (!col.sortable)
        return;
    if (col.sortable === 'invert' && 'k' in col)
        return col.k;
    if (col.sortable === 'invert')
        return col.value;
    if (col.sortable !== true && 'k' in col.sortable)
        return col.sortable.k;
    // @ts-ignore TODO figure out why it thinks `col` is `never`
    if ('k' in col)
        return col.k;
    return col.value;
}
function filterField(col) {
    if (!col.filterable)
        return;
    // @ts-ignore TODO figure out why it thinks `col` is `never
    if (typeof col.filterable !== 'string' && 'k' in col.filterable)
        return col.filterable.k;
    // @ts-ignore TODO figure out why it thinks `col` is `never`
    if ('k' in col)
        return col.k;
    return col.value;
}
function runQuery(client, query, variables) {
    return client.query(query, variables).toPromise();
}
