import React from 'react';
import { withoutTypes, oneByType } from 'react-children-by-type';
import { string } from '@ordaos/util';
import useStatelyProp from "../../hooks/use-stately-prop.js";
import cx from "../../lib/classnames.js";
import Button from "../Button/index.js";
import * as Icon from "../Icon/index.js";
import * as s from './Section.module.scss';
const Section = ({ title, children, figure, collapsible, onToggle, layout, }) => {
    const [collapsed, setCollapsed] = useStatelyProp(collapsible === 'closed');
    const header = oneByType(children, Header);
    const SectionTag = figure ? `figure` : `section`;
    const specificClass = `section-${string.dashify(title)}`;
    return (React.createElement(SectionTag, { className: cx(s, 'section', {
            [layout]: layout,
        }, specificClass) },
        React.createElement("div", { className: cx(s, 'section--header') },
            React.createElement("h2", { className: cx(s, 'section--header-title') },
                title,
                collapsible && (React.createElement(Button, { icon: true, onClick: () => {
                        if (onToggle) {
                            onToggle(!collapsed);
                        }
                        return setCollapsed(!collapsed);
                    } }, collapsed ? React.createElement(Icon.Show, null) : React.createElement(Icon.Hide, null)))),
            header?.props.children && (React.createElement("div", { className: cx(s, 'section--header-actions') }, header?.props.children))),
        !collapsed && (React.createElement("div", { className: cx(s, 'section--content') }, withoutTypes(children, Header)))));
};
export default Section;
const Header = (Section.Header = (props) => {
    return null;
});
