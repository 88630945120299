import React, { useEffect, useMemo } from 'react';
import { scaleLinear } from '@visx/scale';
import { Text } from '@visx/text';
import { Group } from '@visx/group';
import { array as arr, array, object as o } from '@ordaos/util';
import * as Icons from "../../components/Icon/index.js";
import useClickOutside from "../../hooks/use-click-outside.js";
import useWindowEvent from "../../hooks/use-window-event.js";
import useStatelyProp from "../../hooks/use-stately-prop.js";
import cx from "../../lib/classnames.js";
import * as colors from "../../lib/color.js";
import * as t from '../../_tokens/index.theo.module';
import * as s from './ProteinSequence.module.scss';
import CheckboxField from "../../components/CheckboxField/index.js";
import Button from "../../components/Button/index.js";
export function isDiscontinuous(v) {
    if (!v)
        return false;
    return Array.isArray(v[0]);
}
export function isRange(v) {
    if (!v)
        return false;
    return !Array.isArray(v[0]);
}
const AA_WIDTH = 15;
const AA_HEIGHT = 20;
const AA_SPACING = 5;
function ProteinSequence({ children: sequence, selectable, bordered = false, noOverlap = false, legend = true, values = [], perRow = 50, subgroups = [], activeIndex, onHoverResidue, onSelectSubset, ...props }) {
    // HOOKS
    const subsets = useMemo(() => props.subsets?.map(toSubset) ?? [], [props.subsets]);
    const [state, setState] = useStatelyProp({ type: 'continuous' }, { prepare: JSON.stringify });
    const [activeSubsetIndex, setActiveSubsetIndex] = useStatelyProp(props.activeSubsetIndex);
    const activeSubset = subsets[activeSubsetIndex];
    const containerRef = useClickOutside(() => {
        return props.onClickOutside?.();
    });
    useWindowEvent('keyup', (evt) => {
        if (evt.code === 'Escape') {
            setState({ ...state, selected: undefined });
        }
    });
    useEffect(() => {
        if (props.onActivateSubset) {
            props.onActivateSubset(activeSubset?.range, activeSubsetIndex);
        }
    }, [activeSubsetIndex]);
    // COMPUTED VALUES
    const DEFAULT_LEGEND_CONFIG = {
        steps: 5,
        by: 'pct',
        round: false,
    };
    const baseColor = props.colors?.base ?? t.colorOrdaosBlue;
    const legendConfig = typeof legend === 'object' ? legend : DEFAULT_LEGEND_CONFIG;
    const stepKeys = getStepKeys(legendConfig, values || []);
    const DEFAULT_PALETTE = {
        ...generatePaletteSteps(stepKeys),
        empty: t.colorWhite,
        hovered: t.colorWhite,
        removed: t.colorRed,
        blocked: t.colorRed,
        added: t.colorDarkGreen,
        selected: t.colorLightGreen,
        active: t.colorOrdaosBlue,
        subsets: t.colorIce,
        base: t.colorOrdaosBlue,
    };
    const continuousEdit = isRange(activeSubset?.range) &&
        isRange(state?.selected) &&
        activeSubset.range.includes(state?.selected?.[0]);
    const discontinuousEdit = isDiscontinuous(activeSubset?.range) &&
        activeSubset.range.find((r) => isRange(state?.selected) &&
            isRange(r) &&
            r.includes(state.selected[0]));
    const isEditing = activeSubset?.editable && (continuousEdit || discontinuousEdit);
    const rows = arr.chunk(sequence.split(''), perRow);
    const width = perRow * AA_WIDTH;
    const height = props.height ?? rows.length * AA_HEIGHT + rows.length * AA_SPACING;
    const xScale = scaleLinear({ domain: [0, perRow], range: [0, width] });
    const getStrokeDashArray = buildStrokeDashArray(subgroups || []);
    const palette = { ...DEFAULT_PALETTE, ...props.colors };
    const valuePalette = o.filter(palette, (_, k) => !isNaN(parseInt(String(k))));
    return (React.createElement(React.Fragment, null,
        props.allowDiscontinuous && (React.createElement(React.Fragment, null,
            React.createElement(CheckboxField, { value: state.type === 'discontinuous', onChange: (v) => {
                    setActiveSubsetIndex(null);
                    setState({
                        type: v ? 'discontinuous' : 'continuous',
                    });
                }, label: 'Select Discontinuous Subsets' }),
            state.type === 'discontinuous' && (React.createElement(Button, { onClick: () => {
                    if (!selectable)
                        return;
                    const ranges = subsets.map((s) => s.range);
                    const selected = isDiscontinuous(state.selected) &&
                        state.selected.filter((s) => s.length > 0);
                    const updated = [...ranges, selected];
                    if (onSelectSubset) {
                        onSelectSubset(selected, subsets.length);
                    }
                    if (props.onUpdateSubsets) {
                        props.onUpdateSubsets(updated);
                    }
                    setState({
                        ...state,
                        type: 'continuous',
                        selected: null,
                    });
                }, size: 'xs', disabled: !state.selected || state.selected.length === 0 }, "End Discontinuous Selection")))),
        React.createElement("div", { className: cx(s, 'protein-sequence', {
                selectable: selectable,
            }) },
            React.createElement("svg", { ref: containerRef, width: width + 2, height: height + 2, onMouseLeave: () => {
                    setState(o.remove(state ?? { ...state }, 'hovering'));
                } }, rows.map((row, i) => (React.createElement(Group, { transform: `translate(1, ${i * AA_HEIGHT + i * AA_SPACING + 1})`, key: row.join('') },
                React.createElement(Text, { x: -30, y: 15 }, i * perRow + 1),
                row.map((aa, j) => {
                    const trueIndex = i * perRow + j;
                    const strokeDashArray = bordered
                        ? `[0]`
                        : `${getStrokeDashArray(trueIndex)}`;
                    const activateable = subsets?.some(({ range }) => trueIndex >= range[0] &&
                        trueIndex <= range[1]);
                    return (
                    // @prettier-ignore
                    React.createElement(Group, { key: `${aa}-${trueIndex}-${j}`, cursor: props.onClickResidue ||
                            selectable ||
                            activateable
                            ? 'pointer'
                            : 'text', transform: `translate(${xScale(j % perRow)}, 0)`, onClick: (e) => {
                            if (onClickResidue) {
                                onClickResidue(e, trueIndex);
                            }
                        }, onMouseEnter: () => {
                            setState({
                                ...state,
                                hovering: trueIndex,
                            });
                            if (onHoverResidue) {
                                onHoverResidue(trueIndex);
                            }
                        }, onMouseLeave: () => {
                            setState(o.remove(state ?? { hovering: true }, 'hovering'));
                            if (onHoverResidue) {
                                onHoverResidue(null);
                            }
                        }, onMouseDown: (e) => {
                            if (e.metaKey)
                                return;
                            if (!state?.selected ||
                                (state.type ===
                                    'discontinuous' &&
                                    state.selected[state.selected.length -
                                        1].length === 0)) {
                                onStartSelection(trueIndex);
                            }
                            else {
                                onUpdateSelection(trueIndex);
                            }
                        }, onMouseOver: () => {
                            onUpdateSelection(trueIndex);
                        }, onMouseUp: (e) => {
                            if (e.metaKey)
                                return;
                            if ((state?.selected?.length > 1 &&
                                isRange(state.selected)) ||
                                (state.type ===
                                    'discontinuous' &&
                                    state?.selected?.length &&
                                    state.selected[state.selected.length -
                                        1].length > 1)) {
                                onEndSelection();
                            }
                        } },
                        React.createElement("rect", { fill: getColor(trueIndex), width: AA_WIDTH, height: AA_HEIGHT, stroke: 'black', strokeWidth: '2px', strokeDasharray: strokeDashArray }),
                        React.createElement(Text, { fontSize: 13, x: 3, y: 15 }, aa)));
                }))))),
            (legend === true || legend?.display) && (React.createElement(Legend, { colors: valuePalette })))));
    function onClickResidue(e, i) {
        if (props.onClickResidue) {
            props.onClickResidue(i);
        }
        if (e.metaKey) {
            const candidates = filterOverlaps(subsets, i, !props.asIndices)
                .filter((s) => s.editable || s.activateable)
                .map((s) => subsets.indexOf(s));
            if (!candidates?.length)
                return;
            const current = candidates.indexOf(activeSubsetIndex);
            const next = !current && current !== 0 ? candidates[0] : current + 1;
            setState({ type: 'continuous' });
            return setActiveSubsetIndex(candidates[next]);
        }
    }
    function onStartSelection(i) {
        if (!selectable)
            return;
        if (state.type === 'continuous') {
            setState({ ...state, selected: [i] });
        }
        else if (state.type === 'discontinuous') {
            const updatedState = state.selected
                ? array.replaceAt(state.selected, state.selected.length - 1, [
                    i,
                ])
                : [[i]];
            setState({ ...state, selected: updatedState });
        }
        else if (!!state) {
            setState({ type: 'continuous', selected: [i] });
        }
    }
    function onUpdateSelection(i) {
        if (!selectable)
            return;
        if (state?.selected) {
            if (state.type === 'continuous') {
                setState({ ...state, selected: [state?.selected[0], i] });
            }
            if (state.type === 'discontinuous' &&
                !!state.selected &&
                state.selected[state.selected.length - 1].length > 0) {
                const updatingRange = state.selected[state.selected.length - 1];
                const updatingIndex = state.selected.indexOf(updatingRange);
                const updatedSelected = array.replaceAt(state.selected, updatingIndex, [updatingRange[0], i]);
                setState({ ...state, selected: updatedSelected });
            }
        }
    }
    function onEndSelection() {
        if (!selectable)
            return;
        const { selected } = state;
        const ranges = subsets.map((s) => s.range);
        if (isEditing && isRange(selected)) {
            if (isDiscontinuous(activeSubset?.range)) {
                const updatedRangeIndex = activeSubset.range.indexOf(discontinuousEdit);
                const updated = toUpdatedRange(activeSubset.range[updatedRangeIndex], selected);
                const updatedRange = arr.replaceAt(activeSubset.range, updatedRangeIndex, updated);
                if (props.onUpdateSubsets) {
                    props.onUpdateSubsets(arr.replaceAt(ranges, activeSubsetIndex, updatedRange));
                }
                setState({ ...state, selected: null });
            }
            if (isRange(activeSubset?.range)) {
                const updated = toUpdatedRange(activeSubset.range, selected);
                if (props.onUpdateSubsets) {
                    props.onUpdateSubsets(arr.replaceAt(ranges, activeSubsetIndex, updated));
                }
                setState({ ...state, selected: null });
            }
            if (isDiscontinuous(activeSubset.range)) {
            }
        }
        else {
            if (state.type === 'continuous') {
                if (props.onUpdateSubsets) {
                    props.onUpdateSubsets([...ranges, selected]);
                }
                if (onSelectSubset) {
                    onSelectSubset(selected, subsets.length);
                }
                setState({ ...state, selected: null });
            }
            if (state.type === 'discontinuous') {
                setState({ ...state, selected: [...state.selected, []] });
            }
        }
    }
    function getColor(i) {
        const overlappedSubsets = filterOverlaps(subsets, i, !props.asIndices);
        const isSelected = state?.selected && overlaps(state.selected, i, !props.asIndices);
        const isBlocked = isSelected && noOverlap && overlappedSubsets.length;
        const thresholds = Object.keys(valuePalette).map(Number);
        const lowToHigh = thresholds.sort((a, b) => a - b);
        if (activeSubset?.value &&
            i >= activeSubset.range[0] &&
            i <= activeSubset.range[1]) {
            const threshold = lowToHigh.find((t) => activeSubset.value <= t);
            return valuePalette[threshold];
        }
        if (isBlocked)
            return palette.removed;
        if (activeIndex === i)
            return palette.hovered;
        if (state?.hovering === i)
            return palette.hovered;
        const [, end] = isRange(state?.selected) ? state?.selected : [];
        const isActive = !!activeSubset && overlaps(activeSubset.range, i, !props.asIndices);
        const isAdding = isActive && !overlaps(activeSubset.range, end, !props.asIndices);
        const isRemoving = isActive && overlaps(activeSubset.range, end, !props.asIndices);
        const isRemoved = isEditing && isSelected && isRemoving;
        const isAdded = isEditing && isSelected && isAdding;
        if (isRemoved)
            return palette.removed;
        if (isAdded)
            return palette.added;
        if (isSelected)
            return palette.selected;
        if (isActive)
            return palette.active;
        if (overlappedSubsets.length) {
            const subsetColors = overlappedSubsets.reduce((acc, { color: c }) => (c ? [...acc, c] : acc), []);
            const color = colors.blended(...subsetColors) ?? palette.subsets;
            const lighten = overlappedSubsets.length >= 10 ? 0.5 : 0;
            const darken = (overlappedSubsets.length - subsetColors.length) / 10;
            return colors.darken(colors.lighten(color, lighten), darken);
        }
        const threshold = lowToHigh.find((t) => (values?.[i] ?? t) <= t);
        return valuePalette[threshold] ?? palette.empty;
    }
    function getStepKeys(config, values) {
        const { steps, by, round } = config;
        return Array.from({ length: steps }).map((_, i) => {
            if (by === 'pct') {
                return Number(((i + 1) / steps).toFixed(2));
            }
            else if (by === 'maxValue') {
                const stepKey = Math.max(...values) * Number((i + 1) / steps);
                if (round)
                    return Math.round(stepKey);
                return Number(stepKey.toFixed(2));
            }
            else if ('type' in by && by.type === 'range') {
                const stepValue = (by.max - by.min) / steps;
                const stepKey = (i + 1) * stepValue + by.min;
                if (round)
                    return Math.round(stepKey);
                return Number(stepKey.toFixed(2));
            }
        });
    }
    function generatePaletteSteps(steps) {
        return steps.reduce((stepObj, step, i) => {
            if (i === 0) {
                stepObj[step] = t.colorWhite;
                return stepObj;
            }
            else if (i === Object.keys(stepObj).length - 1) {
                stepObj[step] = baseColor;
                return stepObj;
            }
            else {
                stepObj[step] = colors.lighten(baseColor, 1 - i / steps.length);
                return stepObj;
            }
        }, {});
    }
}
const Legend = ({ colors: palette }) => {
    const thresholds = Object.keys(palette).map(Number);
    const highToLow = thresholds.sort((a, b) => b - a);
    return (React.createElement("legend", { className: cx(s, 'protein-sequence', { legend: true }) }, highToLow.map((v, i) => {
        const next = highToLow[i + 1];
        const label = next ? `${next} - ${v}` : `≤ ${v}`;
        return (React.createElement("li", { className: cx(s, 'protein-sequence', {
                'legend-item': true,
            }), key: label },
            React.createElement(Icons.BulletPoint, { fill: palette[v] }),
            ": ",
            label));
    })));
};
export default ProteinSequence;
/**
 * HELPERS
 */
function toSubset(subset) {
    return Array.isArray(subset) ? { range: subset } : subset;
}
function overlaps(range, c, asRange = true) {
    if (isRange(range)) {
        const [a, b] = range;
        if (asRange) {
            if (!b && b !== 0) {
                return (c - a) * (a - c) >= 0;
            }
            return (c - a) * (b - c) >= 0;
        }
        return [a, b].includes(c);
    }
    if (isDiscontinuous(range)) {
        return range.some((r) => overlaps(r, c));
    }
}
function discontinuousOverlaps(ranges, i) {
    return ranges.some((range) => overlaps(range, i));
}
export function filterOverlaps(subsets, i, asRange = true) {
    return subsets.filter((s) => {
        if (isRange(s.range)) {
            return overlaps(s.range, i, asRange);
        }
        else if (isDiscontinuous(s.range)) {
            return discontinuousOverlaps(s.range, i);
        }
        else {
            return false;
        }
    });
}
function buildStrokeDashArray(subgroups) {
    const { starts, mids, ends, endAdjacent, solos } = subgroups.reduce(({ starts, mids, ends, endAdjacent, solos }, [start, end]) => {
        return !end
            ? {
                starts,
                mids,
                ends,
                endAdjacent: [...endAdjacent, start + 1],
                solos: [...solos, start],
            }
            : {
                starts: [...starts, start],
                mids: [...mids, ...arr.range(end - 1, start + 1)],
                ends: [...ends, end],
                endAdjacent: [...endAdjacent, end + 1],
                solos,
            };
    }, { starts: [], mids: [], ends: [], endAdjacent: [], solos: [] });
    return (i) => {
        if (starts.includes(i))
            return [15, 20, 15, 0, 20];
        if (mids.includes(i))
            return [15, 20, 15, 20];
        if (ends.includes(i))
            return [15, 0, 20, 0, 15, 20];
        if (solos.includes(i))
            return [0];
        if (endAdjacent.includes(i))
            return [0, 50, 20];
        return [0, 70];
    };
}
function toUpdatedRange(active, selected) {
    const isAdding = !overlaps(active, selected[1]);
    const isRemoving = overlaps(active, selected[1]);
    const minActive = Math.min(...active.flat());
    const minSelected = Math.min(...selected);
    const maxActive = Math.max(...active.flat());
    const maxSelected = Math.max(...selected);
    if (isAdding) {
        return [
            Math.min(minSelected, minActive),
            Math.max(maxSelected, maxActive),
        ];
    }
    if (isRemoving) {
        if (minActive === minSelected && maxActive === maxSelected) {
            return [selected[1]];
        }
        return arr.difference(active, selected);
    }
}
