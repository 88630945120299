import React from 'react';
import cx from "../../lib/classnames.js";
import { useToggle } from "../../hooks/index.js";
import QuestionSvg from '../../_images/question.svg';
import Modal from "../Modal/index.js";
import * as s from './Icons.module.scss';
const Info = ({ text, children }) => {
    const [showModal, toggleModal] = useToggle(false);
    return (React.createElement("span", { className: cx(s, 'info-wrapper') },
        React.createElement(QuestionSvg, { className: cx(s, 'info--icon', { clickable: !!children }), onClick: () => children && toggleModal() }),
        text && React.createElement("div", { className: cx(s, 'info--text') }, text),
        React.createElement(Modal, { open: showModal, onClose: () => toggleModal() }, children)));
};
export default Info;
