"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFulfilled = exports.never = exports.sequence = exports.async = exports.timeout = exports.delay = exports.poll = void 0;
function poll(delay, cb) {
    return new Promise((res, rej) => {
        let stopped = false; // this let's us kill the interval when done is called
        let running = false; // this let's our cb function take longer than the delay and avoids overlapping calls
        const interval = setInterval(async () => {
            try {
                if (running)
                    return;
                running = true;
                const response = await cb(() => {
                    stopped = true;
                });
                running = false;
                if (stopped) {
                    clearInterval(interval);
                    res(response);
                }
            }
            catch (err) {
                rej(err);
            }
        }, delay);
    });
}
exports.poll = poll;
function delay(n) {
    return new Promise((res) => {
        setTimeout(res, n);
    });
}
exports.delay = delay;
function timeout(p, n) {
    return Promise.race([
        p,
        delay(n).then(() => {
            throw new Error(`timeout`);
        }),
    ]);
}
exports.timeout = timeout;
function async(fn) {
    return new Promise((res) => res(fn()));
}
exports.async = async;
function sequence(fn, arr) {
    return arr.reduce(async (p, v) => {
        const prev = await p;
        const next = await fn(v);
        return prev.concat(next);
    }, Promise.resolve([]));
}
exports.sequence = sequence;
function never() {
    return new Promise(() => { });
}
exports.never = never;
function isFulfilled(result) {
    return result.status === 'fulfilled';
}
exports.isFulfilled = isFulfilled;
