import React, { useState, useRef, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { array, string } from '@ordaos/util';
import cx from "../../lib/classnames.js";
import exportExcel from "../../lib/exports/excel.js";
import download, { DownloadError } from "../../lib/exports/download.js";
import useAsync, { Async } from "../../hooks/use-async.js";
import useRefresh from "../../hooks/use-refresh.js";
import ArrayField from "../ArrayField/index.js";
import Button from "../Button/index.js";
import CheckboxField from "../CheckboxField/index.js";
import InputGroup from "../InputGroup/index.js";
import ObjectField from "../ObjectField/index.js";
import SearchField from "../SearchField/index.js";
import SelectField from "../SelectField/index.js";
import Section from "../Section/index.js";
import * as Icons from "../Icon/index.js";
import Cell from "./Cell.js";
import Column, { heading } from "./Column.js";
import Heading from "./Heading.js";
import useTableState from "./use-table-state.js";
import * as s from './Table.module.scss';
function Table({ title, items, context, searchable = true, exportable, selectable, freeze = true, pageSize, sessionKey, columns: propsColumns, children, onRowsUpdated, onRowClicked, rowHighlight, ...props }) {
    const rawColumns = getColumns({ columns: propsColumns, children });
    const downloadable = !!rawColumns.find((c) => c.downloadable);
    const [getItems, getItemsDeps] = Array.isArray(items) ? items : [items, []];
    const [state, updateState] = useTableState(rawColumns, {
        sessionKey,
        pageSize,
    });
    const { sort, filters, limit, offset, collapsed, selected, columns } = state;
    const [search, setSearch] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const collapsibleCols = columns.filter((c) => c.collapsible);
    const isFullyCollapsed = collapsibleCols.length === collapsed.length;
    const [itemsId, refreshItems] = useRefresh();
    const items_ = useAsync(async () => {
        const res = await getItems({
            filters: filters.map((f) => f.transform?.(f) ?? f),
            sort: sort?.invert ? invertSort(sort) : sort,
            limit,
            offset,
        });
        setTotalCount(res.total);
        if (res.items.length === 0 && res.total > 0) {
            // this ensures as result items are updated offset is always automatically kept relevant
            updateState('offset', (Math.ceil(res.total / limit) - 1) * limit);
        }
        else {
            if (onRowsUpdated) {
                onRowsUpdated(res.items);
            }
        }
        return res;
    }, [state.id, itemsId, ...getItemsDeps]);
    const ref = useTableSizing(items_.status === 'completed' ? items_.value.items.length : pageSize);
    const specificClass = `table-${string.dashify(title)}`;
    return (React.createElement("div", { className: cx(s, 'table', {}, specificClass), ref: ref },
        React.createElement(Section, { title: title },
            React.createElement(Section.Header, null,
                React.createElement("div", { className: cx(s, 'table-header') },
                    selectable && !!selected.length && (React.createElement("span", { className: cx(s, 'table-header--group-actions') },
                        React.createElement(SelectableActions, { selected: selected, actions: selectable.actions, refresh: () => refreshItems(), onClear: () => updateState('selected', []) }))),
                    searchable && (React.createElement("span", { className: cx(s, 'table-header--search') },
                        React.createElement(SearchField, { value: search, onChange: setSearch, onBlur: onSearch, onKeyPress: (evt) => {
                                if (evt.key === 'Enter') {
                                    onSearch(evt.currentTarget.value);
                                }
                            } }))),
                    !!collapsibleCols.length &&
                        (isFullyCollapsed ? (
                        // prettier-ignore
                        React.createElement(Button, { size: 'xs', icon: true, onClick: () => updateState('collapsed', []) },
                            React.createElement(ActionLabel, { label: 'Expand Columns', icon: Icons.Expand }))) : (
                        // prettier-ignore
                        React.createElement(Button, { size: 'xs', icon: true, onClick: () => updateState('collapsed', collapsibleCols.map(heading)) },
                            React.createElement(ActionLabel, { label: 'Collapse Columns', icon: Icons.Collapse })))),
                    exportable && (React.createElement(Button, { icon: true, size: 'xs', disabled: totalCount <= 0, onClick: onExport },
                        React.createElement(ActionLabel, { label: 'Export', icon: Icons.Export }))),
                    downloadable && (React.createElement(Button, { icon: true, size: 'xs', onClick: onDownload },
                        React.createElement(ActionLabel, { label: 'Download', icon: Icons.Download }))))),
            !!filters.length && (React.createElement(Section, { collapsible: true, title: 'Filters' },
                React.createElement("div", { className: cx(s, 'table-filters') },
                    React.createElement("div", { className: cx(s, 'table-filters--actions') },
                        React.createElement(Button, { size: 'xs', onClick: () => updateState('filters', []) }, "Clear Filters")),
                    React.createElement(ArrayField, { value: filters, onChange: (v) => {
                            return updateState('filters', v);
                        }, onRemove: ((v, i) => {
                            // @ts-ignore: Temporary
                            return v.column.filterable?.onRemoveFilter?.(v.expected);
                        }) }, ({ value, ...rest }) => {
                        if (value.column === 'any') {
                            return React.createElement("span", null,
                                "Any ",
                                value.by,
                                " ",
                                value.expected);
                        }
                        return (React.createElement(ObjectField, { label: value.column.heading, value: value, ...rest }, value.column.filterable.render));
                    })))),
            React.createElement("div", { className: cx(s, 'table--wrapper') },
                React.createElement("div", { className: cx(s, 'table--scroll-container') },
                    React.createElement("table", { className: cx(s, 'table--table', {
                            ['freeze-column']: (!selectable && freeze) ||
                                (!!selectable && !freeze),
                            ['freeze-column-selectable']: !!selectable && freeze,
                        }), ...props },
                        React.createElement("thead", { className: cx(s, 'table--header') },
                            React.createElement("tr", null,
                                selectable && (React.createElement(Async, { value: items_, loader: false, failed: false }, ({ items }) => {
                                    const ids = items.map(selectable.id);
                                    const allSelected = ids.every((id) => selected.includes(id));
                                    return (
                                    // prettier-ignore
                                    React.createElement("th", { className: cx(s, 'table--header--select-all') },
                                        React.createElement(CheckboxField, { value: allSelected, onChange: () => allSelected ?
                                                onDeselect(...ids) :
                                                onSelect(...ids) })));
                                })),
                                columns.map((col, i) => (React.createElement(Heading, { key: col.heading, ...col }))))),
                        React.createElement("tbody", { className: cx(s, 'table--body') },
                            React.createElement(Async, { value: items_, failed: (err) => (React.createElement("tr", null,
                                    React.createElement("td", { colSpan: columns.length }, err.stack))), overlay: true }, ({ items }) => (React.createElement(React.Fragment, null, items.map((item, i) => {
                                const style = !!rowHighlight ? { background: rowHighlight(item) } : undefined;
                                return (React.createElement("tr", { key: i, className: cx(s, 'table--body-row'), onClick: () => onRowClicked?.(item), style: style },
                                    selectable &&
                                        React.createElement(Cell, { title: "Select All", heading: "", value: selectable.id, item: item, numRows: items.length, html: () => {
                                                const id = selectable.id(item);
                                                return React.createElement(CheckboxField, { value: selected.includes(id), onChange: () => onToggle(id) });
                                            } }),
                                    columns.map((col) => {
                                        if (col.collapsible?.collapsed && i > 0)
                                            return;
                                        return (React.createElement(Cell, { key: col.heading, ...col, item: item, numRows: items.length, cellHighlight: rowHighlight }));
                                    })));
                            }))))))),
                React.createElement(Async, { loader: false, value: items_ }, ({ total }) => (React.createElement(Pagination, { activePage: offset / pageSize + 1, itemsCountPerPage: limit, totalItemsCount: total, pageRangeDisplayed: 5, prevPageText: React.createElement(Icons.Arrow, { dir: 'left' }), nextPageText: React.createElement(Icons.Arrow, { dir: 'right' }), hideFirstLastPages: true, onChange: (page) => updateState('offset', (page - 1) * limit), innerClass: cx(s, 'table--pagination'), itemClass: cx(s, 'table--pagination-nav'), linkClass: cx(s, 'table--pagination-link'), activeClass: s['active'] })))))));
    function onSearch(expected) {
        if (!expected)
            return;
        const filter = {
            label: 'Any column',
            column: 'any',
            by: 'contains',
            expected,
        };
        updateState('filters', [...filters, filter]);
        setSearch(null);
    }
    async function onExport() {
        const chunkSize = 500;
        const numChunks = Math.ceil(totalCount / chunkSize);
        const exportPromises = await Promise.all(Array.from({ length: numChunks }, async (_, chunkNumber) => {
            const startIdx = chunkNumber * chunkSize;
            const { items } = await getItems({
                limit: chunkSize,
                offset: startIdx,
                sort,
                filters,
            });
            const xlsRows = items.map((item) => ({ item }));
            const xlsCols = columns
                .filter((c) => c.exportable ?? true)
                .flatMap((c) => {
                if (Array.isArray(c.exportable))
                    return c.exportable;
                return {
                    ...c,
                    fmt: c.format?.excel,
                };
            });
            return exportExcel(xlsRows, xlsCols, {
                title,
                context: {
                    ...context,
                    items: `${startIdx + 1}-${startIdx + items.length}`,
                },
            });
        }));
        await Promise.allSettled(exportPromises);
    }
    async function onDownload() {
        const chunkSize = 500;
        const numChunks = Math.ceil(totalCount / chunkSize);
        const downloadPromises = await Promise.all(Array.from({ length: numChunks }, async (_, chunkNumber) => {
            const startIdx = chunkNumber * chunkSize;
            const { items } = await getItems({
                limit: chunkSize,
                offset: startIdx,
                sort,
                filters,
            });
            const downloadables = columns
                .filter((c) => c.downloadable)
                .flatMap((c) => c.downloadable);
            if (!downloadables.length)
                return null;
            const files = array
                .matrix(downloadables, items)
                .reduce((acc, [d, item]) => {
                const { url: getUrl, name: getName, onFailure } = d;
                const url = getUrl(item);
                if (!url)
                    return acc;
                const name = getName(item) ?? url;
                return [...acc, { url, name, onFailure, item }];
            }, []);
            return download(`${title}:${chunkNumber * chunkSize}-${(chunkNumber + 1) * chunkSize}.zip`, {
                urls: array.toRecord(files, (d) => d.name, (d) => d.url),
                onFail(res, i) {
                    const { onFailure, item } = files[i];
                    if (res instanceof DownloadError) {
                        return onFailure?.(item, res.response);
                    }
                    return onFailure?.(item, res);
                },
            });
        }));
        const downloads = downloadPromises.filter(Boolean);
        await Promise.allSettled(downloads);
    }
    function onSelect(...ids) {
        updateState('selected', array.union(selected, ids));
    }
    function onDeselect(...ids) {
        updateState('selected', array.difference(selected, ids));
    }
    function onToggle(...ids) {
        updateState('selected', ids.reduce((acc, id) => array.toggleIn(acc, id), selected));
    }
}
export default Table;
Table.Column = Column;
export function getColumns(props) {
    return (props.columns ??
        React.Children.toArray(props.children).map(({ props }) => props));
}
function useTableSizing(pageSize) {
    const componentRef = useRef();
    useEffect(() => {
        if (componentRef.current?.style?.setProperty) {
            componentRef.current.style.setProperty('--num-rows', `${(pageSize ?? 0) + 4}`);
        }
    }, [pageSize]);
    return componentRef;
}
function SelectableActions({ selected, actions, refresh, onClear, }) {
    const [action, setAction] = useState(null);
    const options = Object.entries(actions).map(([label, value]) => ({
        label,
        value,
    }));
    return (React.createElement(InputGroup, null,
        React.createElement(SelectField, { placeholder: 'Actions', options: options, value: action, onChange: (v) => setAction(() => v) }),
        React.createElement(Button, { size: 'xs', onClick: async () => {
                await action(selected, onClear);
                setAction(null);
                refresh();
            }, disabled: !action }, "Go")));
}
const ActionLabel = ({ icon: Icon, label }) => {
    return (React.createElement("span", { className: cx(s, 'table-header--action-label') },
        Icon && React.createElement(Icon, null),
        React.createElement("span", { className: cx(s, 'table-header--action-label-text') }, label)));
};
// Helpers
function invertSort(sort) {
    if (!sort)
        return;
    switch (sort.direction) {
        case 'asc':
            return { ...sort, direction: 'desc' };
        case 'desc':
            return { ...sort, direction: 'asc' };
        default:
            throw new Error('Invalid sort direction');
    }
}
