import React, { useState } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { RichText } from '@ordaos/nucleus'
import imgLogo from 'img/logo-footer.svg'
import Link from '../../components/Link'

import './Footer.scss'

const Footer = () => {
	const [email, setEmail] = useState(null)
	const [message, setMessage] = useState(null)

	async function handleSubmit() {
		setMessage(null)
		const formData = {
			fields: [{ name: 'email', value: email }],
		}

		try {
			await fetch(
				`https://api.hsforms.com/submissions/v3/integration/submit/${process.env.HUBSPOT_TRACKING_CODE}/${process.env.HUBSPOT_CONTACT_FORM_ID}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(formData),
				}
			)
			setMessage('Submission Successful')
		} catch (e) {
			console.error(e)
			setMessage('There was an error, please try again')
		}
	}

	return (
		<footer className='footer'>
			<RichText inverted>
				<div className='footer-container'>
					<div className='footer-main'>
						<div className='nav-wrapper'>
							<GatsbyLink to='/' className='logo'>
								<img src={imgLogo} alt='Ordaos' />
							</GatsbyLink>
							<div className='links'>
								<Link to='/press'>News</Link>
								<Link to='/team'>Team</Link>
								<Link to='/contact-us'>Contact Us</Link>
								<Link to='/team/#careers'>Careers</Link>
							</div>
						</div>
						<div className='menus'>
							<FooterMenu title='Connect'>
								<div className='social-icons'>
									<a
										href='https://www.linkedin.com/company/emergentdynamics/'
										className='social-icon linkedin'
									>
										<FontAwesomeIcon
											icon={fab.faLinkedin}
										/>
									</a>
									<a
										href='https://www.ordaos.bio'
										className='social-icon share'
									>
										<FontAwesomeIcon icon={faShareAlt} />
									</a>
								</div>
							</FooterMenu>
							<FooterMenu title='Partner'>
								<li>
									<Link
										to='/contact-us'
										className='ord-u-type-preset-7 footer-link'
									>
										Contact Us
									</Link>
								</li>
							</FooterMenu>
							<FooterMenu title='Join'>
								<Link
									to='/team/#careers'
									className='ord-u-type-preset-7 footer-link'
								>
									Careers
								</Link>
								<p className='tiny'>
									228 Park Ave S 60684,
									<br /> New York, NY 10003, USA
								</p>
								<p className='tiny'>
									<a href='/privacy-policy'>Privacy Policy</a>
									{' | '}
									<a href='/terms-of-use'>Terms of Use</a>
								</p>
							</FooterMenu>
						</div>
					</div>
				</div>
			</RichText>
		</footer>
	)
}

export default Footer

const FooterMenu = ({ title, children }) => {
	return (
		<div>
			<h4 className='ord-u-type-preset-11 footer-menu--title'>{title}</h4>
			<div className='footer-menu--description'>
				<ul className='footer-menu--nav'>{children}</ul>
			</div>
		</div>
	)
}

const FooterMenuItem = (props) => {
	return (
		<li>
			<Link {...props} nav />
		</li>
	)
}
