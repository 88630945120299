import React, { useState, useEffect } from 'react';
import { browser } from '@ordaos/util';
import cx from "../../lib/classnames.js";
import * as s from './Button.module.scss';
import * as Icons from "../Icon/index.js";
const Button = ({ icon, link, block, submit, size = 'sm', variant = 'primary', preventDefault = true, stopPropagation = true, onClick, ...other }) => {
    const [clickResult, setClickResult] = useState();
    const className = cx(s, 'button', {
        icon,
        link,
        block,
        [size]: size,
        [variant]: variant,
        promised: !!clickResult,
    });
    useEffect(() => {
        Promise.resolve(clickResult).then(setClickResult);
    }, [clickResult]);
    if (typeof onClick === 'string') {
        return (React.createElement("a", { href: onClick },
            React.createElement("button", { ...other, className: className })));
    }
    if (submit) {
        return (React.createElement("input", { disabled: other.disabled, type: 'submit', value: other.children, className: className }));
    }
    return (React.createElement("button", { ...other, className: className, onClick: (e) => {
            if (preventDefault) {
                e.preventDefault();
            }
            if (stopPropagation) {
                e.stopPropagation();
            }
            if (onClick) {
                setClickResult(onClick(e));
            }
        }, children: !!clickResult ? React.createElement(Icons.Loading, null) : other.children }));
};
export const CopyButton = ({ data, label = '', onCopied, children, ...rest }) => {
    return (React.createElement(Button, { ...rest, onClick: () => {
            const stringified = typeof data === 'object'
                ? JSON.stringify(data, null, 4)
                : `${data}`;
            browser.copyToClipboard(stringified);
            if (!!onCopied)
                onCopied(stringified);
        } }, children ?? `Copy ${label}`.trim()));
};
export default Button;
