import React from 'react';
import cx from "../../lib/classnames.js";
import * as s from './RichText.module.scss';
const RichText = ({ inverted, ...rest }) => {
    return React.createElement("div", { ...rest, className: cx(s, 'rich-text', { inverted }) });
};
export default RichText;
export const HeroH = (props) => {
    const className = cx(s, 'hero-h', {}, props.className);
    return React.createElement("h1", { ...props, className: className });
};
export const HeroP = (props) => {
    const className = cx(s, 'hero-p', {}, props.className);
    return React.createElement("p", { ...props, className: className });
};
const H = ({ tag: Tag, inverted, ...props }) => {
    const className = cx(s, Tag, { inverted }, props.className);
    return React.createElement(Tag, { ...props, className: className });
};
export const H1 = (props) => React.createElement(H, { tag: 'h1', ...props });
export const H2 = (props) => React.createElement(H, { tag: 'h2', ...props });
export const H3 = (props) => React.createElement(H, { tag: 'h3', ...props });
export const H4 = (props) => React.createElement(H, { tag: 'h4', ...props });
