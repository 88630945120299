import React, { useEffect, useRef } from 'react';
import cx from "../../lib/classnames.js";
import useStatelyProp from "../../hooks/use-stately-prop.js";
import { allByType } from 'react-children-by-type';
import * as s from './Menu.module.scss';
import * as Icons from "../Icon/index.js";
const Menu = ({ label, active: isActive = false, highlight, inline = false, icon: Icon, children, }) => {
    const [active, setActive] = useStatelyProp(isActive);
    const containerRef = useRef();
    const items = allByType(children, Item);
    const isInlineItemActive = inline && !!items.find(({ props }) => props.active);
    useEffect(() => {
        if (active && !inline) {
            document.addEventListener(`click`, onClickOutside);
            return () => document.removeEventListener(`click`, onClickOutside);
        }
        else {
            document.removeEventListener(`click`, onClickOutside);
        }
        function onClickOutside(e) {
            if (!containerRef.current.contains(e.target)) {
                setActive(false);
            }
        }
    }, [active, containerRef, inline]);
    useEffect(() => {
        setActive(isInlineItemActive);
    }, [isInlineItemActive, setActive]);
    const showContainer = active || inline;
    return (React.createElement("div", { ref: containerRef, className: cx(s, 'menu', { inline, active }) },
        React.createElement("button", { onClick: onToggle, className: cx(s, 'menu-toggle', {
                highlight,
            }) },
            Icon && React.createElement(Icon, null),
            React.createElement("span", { className: cx(s, 'menu-toggle--label') }, label),
            !inline && React.createElement(Icons.Arrow, { dir: 'down' })),
        showContainer && (React.createElement("div", { className: cx(s, 'menu-container', { inline }) },
            React.createElement("ul", { className: cx(s, 'menu-container--list') }, items.map((item) => item))))));
    async function onToggle() {
        if (inline) {
            return items[0]?.props.onClick?.();
        }
        else {
            return setActive(!active);
        }
    }
};
export default Menu;
const Item = (Menu.Item = (props) => {
    const { label, onClick, active } = props;
    return (React.createElement("li", { className: cx(s, 'menu-container--list-item', { active }) },
        React.createElement("button", { onClick: onClick, className: cx(s, 'menu-container--list-item-button') },
            React.createElement("span", { className: cx(s, 'menu-container--list-item-button-label', {
                    active,
                }) }, label))));
});
