import React, { useState, useEffect, useRef, useMemo } from 'react';
import cx from "../../lib/classnames.js";
import * as s from './StructureViewer.module.scss';
import * as NGL from 'ngl';
import { object, func } from '@ordaos/util';
import { toHexString } from "../../lib/color.js";
const StructureRender = ({ file, confidencePerResidue, display, onHover, hoveredPSIndex, view, colors, loadOpts, }) => {
    const [model, setModel] = useState(null);
    const [pageMounted, setPageMounted] = useState(false);
    const containerRef = useRef();
    const viewer = useMemo(() => {
        return createViewer();
    }, [containerRef.current]);
    useEffect(() => {
        setPageMounted(true);
    }, []);
    useEffect(() => {
        loadFile();
    }, [file, display, confidencePerResidue, viewer]);
    useEffect(() => {
        reposition(view);
    }, [view]);
    useEffect(() => {
        if (model) {
            onProteinSequenceHover();
        }
    }, [hoveredPSIndex]);
    // @ts-ignore
    const colorScheme = NGL.ColormakerRegistry.addScheme(function () {
        this.atomColor = function (atom) {
            const { residueIndex } = atom;
            const atomConfidence = confidencePerResidue[residueIndex];
            return getColor(atomConfidence).color;
        };
    });
    return React.createElement("div", { ref: containerRef, className: cx(s, 'structure-viewer') });
    function createViewer() {
        if (containerRef.current) {
            // @ts-ignore
            return new NGL.Stage(containerRef.current, {
                backgroundColor: 'white',
                defaultRepresentation: true,
                tooltip: false,
            });
        }
    }
    function loadFile() {
        if (viewer) {
            viewer.removeAllComponents();
            viewer.loadFile(file, loadOpts).then((o) => {
                setModel(o);
                o.addRepresentation(display, {
                    color: colorScheme,
                });
                o.autoView();
            });
            viewer.signals.hovered.add(func.debounce((proxy) => {
                if (!proxy) {
                    return onHover(null);
                }
                onHover(proxy.atom.residue);
            }, 100));
        }
    }
    function onProteinSequenceHover() {
        if (object.exists(hoveredPSIndex)) {
            const residue = model?.structure.getResidueProxy(hoveredPSIndex);
            onHover(residue);
        }
        else {
            onHover(null);
        }
    }
    async function reposition(view) {
        if (!view)
            return;
        if (view.position === 'center') {
            viewer.autoView();
        }
        else {
            await model.stage.animationControls.zoom(-100, 100);
            await model.stage.animationControls.move({
                x: model.structure.getResidueProxy(view.position).x,
                y: model.structure.getResidueProxy(view.position).y,
                z: model.structure.getResidueProxy(view.position).z,
            }, 500);
            await model.stage.animationControls.zoom(-50, 100);
        }
    }
    function getColor(c) {
        const thresholds = Object.keys(colors).map(Number);
        const lowToHigh = thresholds.sort((a, b) => a - b);
        const threshold = lowToHigh.find((t) => c <= t);
        return { color: convertHexValue(toHexString(colors[threshold])) };
    }
};
export default React.memo(StructureRender);
function convertHexValue(hex) {
    return hex.replace('#', '0x');
}
