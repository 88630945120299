import { array, object } from '@ordaos/util';
import useRefresh from "../../hooks/use-refresh.js";
import { useSession } from "../../hooks/use-storage.js";
import { heading, filterable as getFilterable, isMultiviewableColumn, } from "./Column.js";
function useTableState(cols, opts = {}) {
    const { sessionKey, pageSize = 10 } = opts;
    const [id, refresh] = useRefresh();
    const [session, setSession] = useSession(sessionKey);
    const state = deserialize(session);
    const updateState = (k, v) => {
        const next = typeof k === 'string' ? { [k]: v } : k;
        const { id, columns, ...updates } = next;
        const keys = Object.keys(updates);
        setSession(serialize({ ...state, ...updates }));
        // the refresh id is used to trigger re-loading items, so we only refresh on the dependencies of the items prop
        if (array.intersect(['filters', 'sort', 'limit', 'offset'], keys).length)
            refresh();
    };
    return [state, updateState];
    function serialize(state) {
        const { sort, filters, columns, ...rest } = state;
        return {
            sort: sort && {
                heading: heading(sort.column),
                ...sort,
            },
            filters: filters.map(({ column, ...filter }) => ({
                heading: column !== 'any' ? heading(column) : undefined,
                ...filter,
            })),
            views: columns.reduce((views, col) => {
                if (col.html?.view)
                    return { ...views, [heading(col)]: col.html.view };
                return views;
            }, {}),
            ...rest,
        };
    }
    function deserialize(session) {
        const sort = session?.sort;
        const filters = session?.filters ?? [];
        const views = session?.views ?? {};
        const limit = session?.limit ?? pageSize;
        const offset = session?.offset ?? 0;
        const collapsed = session?.collapsed ??
            cols.filter((c) => c.collapsible === 'closed').map(heading);
        const selected = session?.selected ?? [];
        const columns = cols.map(toTableColumn);
        const multiviewColumns = cols.filter(isMultiviewableColumn);
        return {
            id,
            sort: sort && {
                column: columns.find((col) => heading(col) === sort.heading),
                ...sort,
            },
            filters: filters?.map(({ heading: h, ...filter }) => ({
                column: h
                    ? columns.find((col) => heading(col) === h)
                    : 'any',
                transform: columns.find((col) => heading(col) === h)
                    .filterable.transform,
                ...filter,
            })) ?? [],
            collapsed,
            selected,
            limit,
            offset,
            columns,
        };
        function toTableColumn(col) {
            const { value, exportable, downloadable, format, align, info } = col;
            const columnHeading = heading(col);
            const column = {
                title: col.title ?? columnHeading,
                heading: columnHeading,
                value: object.value(value),
                html: html(),
                format,
                align,
                exportable,
                downloadable,
                sortable: sortable(),
                filterable: filterable(),
                collapsible: collapsible(),
                info,
            };
            return column;
            function sortable() {
                if (!col.sortable)
                    return;
                const active = !!sort && sort.heading === columnHeading;
                const sorted = active ? sort.direction : false;
                const direction = sorted === 'desc' ? 'asc' : 'desc';
                const invert = col.sortable === 'invert';
                const onSortFn = typeof col.sortable === 'object' && col.sortable.onSort;
                return {
                    sorted,
                    onSort: () => {
                        !!onSortFn && onSortFn({ ...column, direction });
                        return updateState('sort', {
                            column,
                            direction,
                            invert,
                        });
                    },
                };
            }
            function filterable() {
                if (!col.filterable)
                    return;
                const { default: init, render, onFilter: transform, onRemoveFilter, } = getFilterable(col);
                return {
                    disabled: false,
                    onFilter: () => {
                        const filter = { column, ...init() };
                        updateState('filters', [...state.filters, filter]);
                    },
                    onRemoveFilter: (f) => {
                        onRemoveFilter(f);
                    },
                    render,
                    transform: (f) => ({
                        column,
                        ...transform(f),
                    }),
                };
            }
            function collapsible() {
                if (!col.collapsible)
                    return;
                return {
                    collapsed: collapsed.includes(columnHeading),
                    onToggle: () => updateState('collapsed', array.toggleIn(collapsed, columnHeading)),
                };
            }
            function html() {
                if (!isMultiviewableColumn(col)) {
                    return col.children;
                }
                else {
                    const selectedView = views[columnHeading] ?? Object.keys(col.views)?.[0];
                    const html = col.views[selectedView];
                    html.view = selectedView;
                    html.views = Object.keys(col.views);
                    html.onChangeView = (view) => {
                        const columnsToUpdate = multiviewColumns.filter((c) => c.viewKey === col.viewKey);
                        const keyViewObject = columnsToUpdate.reduce((acc, c) => {
                            return {
                                ...acc,
                                [heading(c)]: view,
                            };
                        }, {});
                        upsertView(keyViewObject);
                    };
                    return html;
                }
            }
            function upsertView(keyViewObject) {
                setSession({
                    ...session,
                    views: { ...session?.views, ...keyViewObject },
                });
                refresh();
            }
        }
    }
}
export default useTableState;
