import React from 'react';
import Input from "../Input/index.js";
import FormField from "../FormField/index.js";
import cx from "../../lib/classnames.js";
import * as s from './Toggle.module.scss';
const Toggle = (props) => {
    return (React.createElement(FormField, { label: props.label }, () => (React.createElement("div", { className: cx(s, 'toggle') },
        React.createElement(Input, { ...props, type: 'checkbox' }),
        React.createElement("span", { className: cx(s, 'toggle-slider') })))));
};
export default Toggle;
