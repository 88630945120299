import React, { useState } from 'react';
import cx from "../../lib/classnames.js";
import { allByType } from 'react-children-by-type';
import Button from "../Button/index.js";
import * as s from './Tabs.module.scss';
const Tabs = ({ children }) => {
    const [active, setActive] = useState(0);
    const tabs = allByType(children, Tab);
    return (React.createElement("div", { className: cx(s, 'tabs') },
        tabs.map((tab, i) => {
            const { title } = tab.props;
            return (React.createElement(Button, { icon: true, onClick: () => setActive(i), key: title },
                React.createElement("span", { className: cx(s, 'tabs-tab', {
                        active: i === active,
                    }) }, title)));
        }),
        React.createElement("div", { className: cx(s, 'tabs-content') }, tabs[active].props.children)));
};
export default Tabs;
const Tab = (Tabs.Tab = (props) => {
    return null;
});
