import React from 'react';
import { allByType } from 'react-children-by-type';
import cx from "../../lib/classnames.js";
import Button from "../Button/index.js";
import * as s from './ButtonGroup.module.scss';
const ButtonGroup = ({ block, children, ...other }) => {
    const className = cx(s, 'button-group', { block });
    const buttons = allByType(children, Button);
    return (React.createElement("div", { ...other, className: className }, buttons.map((button) => {
        return (React.createElement("div", { key: `${button.key}-wrapper`, className: cx(s, 'button-group--button') }, React.cloneElement(button, { block })));
    })));
};
export default ButtonGroup;
