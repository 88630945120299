import cx from 'classnames';
function classnames(s, base, mods = {}, ...cxArgs) {
    const style = (s.default ?? s);
    const name = `ord-c-${base}`;
    const cxMods = Object.entries(mods).reduce((acc, [mod, v]) => ({
        ...acc,
        [style[`${name}--${mod}`]]: !!v,
    }), {});
    return cx(`${name}-dangerous`, style[name], cxMods, ...cxArgs);
}
export default classnames;
