import React from 'react';
import { useDropzone } from 'react-dropzone';
import cx from "../../lib/classnames.js";
import * as s from './UploadField.module.scss';
const UploadField = ({ disabled, onContent, allowed, uploadLimit, options = {}, }) => {
    function onDrop([raw]) {
        if (options && options.raw) {
            onContent(raw);
        }
        else {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                onContent(data, raw);
            };
            reader.readAsText(raw);
        }
    }
    const dropzoneProps = allowed ? { onDrop, accept: allowed } : { onDrop };
    const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneProps);
    return (React.createElement("div", { ...getRootProps({ className: cx(s, 'upload-field') }) },
        React.createElement("input", { ...getInputProps() }),
        isDragActive ? (React.createElement("p", null, "Drop the files here ...")) : (React.createElement("p", null, "Drag 'n' drop files here, or click to select files")),
        uploadLimit && React.createElement("p", null,
            "Max File Size: ",
            uploadLimit,
            "MB"),
        allowed && React.createElement("p", null,
            "File Types Allowed: ",
            allowed.join(', '))));
};
export default UploadField;
