import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Link from '../../components/Link'
import imgLogo from 'img/logo-nav.png'
import imgContour from 'img/hero-contour.svg'
import imgScatter from 'img/hero-scatter.svg'
import imgGyro from 'img/hero-gyro.svg'
import imgWave from 'img/hero-wave.svg'

import './Header.scss'

const Header = ({ title, subtitle, hero }) => {
	const [navOpen, setNavOpen] = useState(false)

	const mainNavClasses = cx('main-nav--wrapper', {
		show: navOpen,
	})

	return (
		<header className='header'>
			<nav className='header-nav'>
				<div className='logo-wrapper'>
					<a href='/' className='logo-link'>
						<img src={imgLogo} alt='Ordaos' className='logo' />
					</a>
				</div>
				<div className={mainNavClasses}>
					<ul className='main-nav--list'>
						<NavLink to='/press'>News</NavLink>
						<NavLink to='/events'>Events</NavLink>
						<NavLink to='/team'>Team</NavLink>
						<NavLink to='/contact-us'>Contact Us</NavLink>
					</ul>
				</div>
				<button className='burger' onClick={() => setNavOpen(!navOpen)}>
					<FontAwesomeIcon icon={faBars} />
				</button>
			</nav>
			{title && (
				<div className='header-hero'>
					{hero === 'contour' && (
						<img
							src={imgContour}
							alt=''
							className='header-hero--image'
						/>
					)}
					{hero === 'scatter' && (
						<img
							src={imgScatter}
							alt=''
							className='header-hero--image'
						/>
					)}
					{hero === 'gyro' && (
						<img
							src={imgGyro}
							alt=''
							className='header-hero--image'
						/>
					)}
					{hero === 'wave' && (
						<img
							src={imgWave}
							alt=''
							className='header-hero--image'
						/>
					)}
					<h1 className='header-hero--title ord-u-type-preset-6'>
						{title}
					</h1>
					{subtitle && (
						<p className='header-hero--subtitle ord-u-type-preset-3'>
							{subtitle}
						</p>
					)}
				</div>
			)}
		</header>
	)
}

Header.propTypes = {
	title: PropTypes.string,
	hero: PropTypes.oneOf(['contour', 'gyro', 'scatter', 'wave']),
}

export default Header

const NavLink = (props) => {
	return (
		<li className='main-nav--item'>
			<Link {...props} nav />
		</li>
	)
}
