"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.interpolate = exports.split = exports.matrix = exports.sort = exports.filter = exports.chunk = exports.zeros = exports.toRecord = exports.toObject = exports.intersect = exports.difference = exports.union = exports.arrayOfArraysEqual = exports.scalarEqual = exports.uniqueBy = exports.unique = exports.toggleIn = exports.replaceAt = exports.replace = exports.removeAt = exports.remove = exports.moveTo = exports.range = exports.zip = exports.zipWith = exports.toArray = void 0;
const object_1 = require("./object");
function toArray(arg) {
    return Array.isArray(arg) ? arg : [arg];
}
exports.toArray = toArray;
function zipWith(fn, a, b) {
    return a.map((v, i) => fn(v, b[i]));
}
exports.zipWith = zipWith;
function zip(a, b) {
    return zipWith((a, b) => [a, b], a, b);
}
exports.zip = zip;
function range(argHigh, argLow = 0, step = 1) {
    const high = argHigh > argLow ? argHigh : argLow;
    const low = argHigh < argLow ? argHigh : argLow;
    const length = Math.abs(high - low) / step + 1;
    return Array.from({ length }).map((_, i) => low + i * step);
}
exports.range = range;
function moveTo(arr, item, newIndex) {
    const initialIndex = arr.indexOf(item);
    if (initialIndex < 0 ||
        initialIndex >= arr.length ||
        newIndex < 0 ||
        newIndex >= arr.length) {
        return arr;
    }
    const filteredArray = removeAt(arr, initialIndex);
    return [
        ...filteredArray.slice(0, newIndex),
        item,
        ...filteredArray.slice(newIndex),
    ];
}
exports.moveTo = moveTo;
function remove(arr, ...items) {
    return items.reduce((a, v) => removeAt(a, a.indexOf(v)), arr);
}
exports.remove = remove;
function removeAt(arr, i) {
    if (i < 0 || i >= arr.length)
        return arr;
    return [...arr.slice(0, i), ...arr.slice(i + 1)];
}
exports.removeAt = removeAt;
function replace(arr, item, newItem) {
    return replaceAt(arr, arr.indexOf(item), newItem);
}
exports.replace = replace;
function replaceAt(arr, i, newItem) {
    if (i < 0 || i >= arr.length)
        return arr;
    return [...arr.slice(0, i), newItem, ...arr.slice(i + 1)];
}
exports.replaceAt = replaceAt;
function toggleIn(arr, item, compare) {
    let check = item;
    if (compare) {
        check = arr.find((b) => compare(item, b));
        if (check === undefined)
            return arr.concat(item);
    }
    return arr.includes(check) ? remove(arr, check) : arr.concat(check);
}
exports.toggleIn = toggleIn;
function unique(arr) {
    return uniqueBy(arr, (v) => v);
}
exports.unique = unique;
function uniqueBy(arr, fn) {
    return arr.filter((e, i) => arr.findIndex((a) => fn(a) === fn(e)) === i);
}
exports.uniqueBy = uniqueBy;
function scalarEqual(a, b) {
    return a.length === b.length && a.every((v, i) => v === b[i]);
}
exports.scalarEqual = scalarEqual;
function arrayOfArraysEqual(a, b) {
    if (a.length !== b.length) {
        return false;
    }
    for (let i = 0; i < a.length; i++) {
        if (!scalarEqual(a[i], b[i])) {
            return false;
        }
    }
    return true;
}
exports.arrayOfArraysEqual = arrayOfArraysEqual;
function union(a, b) {
    return unique([...a, ...b]);
}
exports.union = union;
function difference(a, b) {
    return [
        ...a.filter((v) => !b.includes(v)),
        ...b.filter((v) => !a.includes(v)),
    ];
}
exports.difference = difference;
function intersect(a, b) {
    return a.filter((v) => b.includes(v));
}
exports.intersect = intersect;
function toObject(arr, keys) {
    return keys.reduce((acc, k, i) => ({ ...acc, [k]: arr[i] }), {});
}
exports.toObject = toObject;
function toRecord(arr, k, v) {
    return arr.reduce((acc, item) => ({ ...acc, [k(item)]: v(item) }), {});
}
exports.toRecord = toRecord;
function zeros(length) {
    return Array.from({ length }).map(() => 0);
}
exports.zeros = zeros;
function chunk(array, size) {
    const chunked = array.slice(0, size);
    return array.length > size
        ? [chunked].concat(chunk(array.slice(size, array.length), size))
        : [chunked];
}
exports.chunk = chunk;
function filter(arr, ...filters) {
    return arr.filter((v) => filters.reduce((acc, filter) => {
        if (!acc)
            return false;
        if (typeof filter === 'function') {
            return filter(v);
        }
        else if (typeof filter.key === 'string') {
            return v[filter.key] === filter.expected;
        }
        else if (typeof filter.key === 'function') {
            return filter.key(v) === filter.expected;
        }
        return acc;
    }, true));
}
exports.filter = filter;
function sort(arr, k, dir = 'asc') {
    const copy = [...arr];
    const get = (0, object_1.value)(k);
    return copy.sort((a, b) => {
        if (dir === 'asc') {
            return get(a) > get(b) ? 1 : -1;
        }
        else {
            return get(a) > get(b) ? -1 : 1;
        }
    });
}
exports.sort = sort;
function matrix(arr1, arr2) {
    return arr1.flatMap((i1) => arr2.map((i2) => [i1, i2]));
}
exports.matrix = matrix;
function split(arr, fn) {
    return arr.reduce(([t, f], v) => {
        return fn(v) ? [[...t, v], f] : [t, [...f, v]];
    }, [[], []]);
}
exports.split = split;
function interpolate(vs, [newMin, newMax], [originalMin, originalMax]) {
    return vs.map(linearInterpolation([newMin, newMax], [originalMin, originalMax]));
}
exports.interpolate = interpolate;
function linearInterpolation(tgt, original) {
    const [minSrc, maxSrc] = [Math.min(...original), Math.max(...original)];
    const [minTgt, maxTgt] = [Math.min(...tgt), Math.max(...tgt)];
    const tgtDelta = maxTgt - minTgt;
    const srcDelta = maxSrc - minSrc;
    return (v) => ((v - minSrc) * tgtDelta) / srcDelta + minTgt;
}
