function isHSL(c) {
    return c.startsWith('hsl');
}
function isHex(c) {
    return c.startsWith('#');
}
function toHSL(c) {
    c = toHSLString(c);
    const [h, s, l] = c.match(/\d+/g).map(Number);
    return { h, s, l };
}
function toString({ h, s, l }) {
    return `hsl(${h},${s}%,${l}%)`;
}
export function toHSLString(c) {
    if (isHSL(c))
        return c;
    const [r, g, b] = c.match(/[a-f\d]{2}/gi).map((x) => parseInt(x, 16) / 255);
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;
    if (max === min) {
        h = s = 0; // achromatic
    }
    else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
            default:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }
    h = s = Math.round(s * 100);
    l = Math.round(l * 100);
    return toString({ h, s, l });
}
export function toHexString(c) {
    if (isHex(c))
        return c;
    const { h, s, l } = toHSL(c);
    const hX = h / 360;
    const sX = s / 100;
    const lX = l / 100;
    let r, g, b;
    if (sX === 0) {
        r = g = b = l;
    }
    else {
        const hue2rgb = function (p, q, t) {
            if (t < 0)
                t += 1;
            if (t > 1)
                t -= 1;
            if (t < 1 / 6)
                return p + (q - p) * 6 * t;
            if (t < 1 / 2)
                return q;
            if (t < 2 / 3)
                return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };
        const q = lX < 0.5 ? lX * (1 + sX) : lX + sX - lX * sX;
        const p = 2 * lX - q;
        r = hue2rgb(p, q, hX + 1 / 3);
        g = hue2rgb(p, q, hX);
        b = hue2rgb(p, q, hX - 1 / 3);
    }
    const toHex = function (x) {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
export function lighten(c, amt) {
    const { h, s, l } = toHSL(c);
    return toString({ h, s, l: l + (100 - l) * amt });
}
export function darken(c, amt) {
    const { h, s, l } = toHSL(c);
    return toString({ h, s, l: l - l * amt });
}
export function mix(c1, c2, ratio = 0.5) {
    ratio = Math.abs(ratio);
    const pct1 = ratio / Math.pow(10, `${Math.floor(ratio)}`.length);
    const pct2 = 1 - pct1;
    const hsl1 = toHSL(c1);
    const hsl2 = toHSL(c2);
    return toString({
        h: hsl1.h * pct1 + hsl2.h * pct2,
        s: hsl1.s * pct1 + hsl2.s * pct2,
        l: hsl1.l * pct1 + hsl2.l * pct2,
    });
}
export function blended(...colors) {
    if (colors.length === 0)
        return null;
    if (colors.length === 1)
        return toHSLString(colors[0]);
    const empty = toString({ h: 0, s: 0, l: 0 });
    const blend = colors.reduce((c1, c2, i) => mix(c2, c1, 1 / (i + 1)), empty);
    return blend;
}
