import React from 'react';
import cx from "../../lib/classnames.js";
import Input from "../Input/index.js";
import FormField from "../FormField/index.js";
import * as s from './TextField.module.scss';
const TextField = ({ rounded, pattern, accept, value, nullable, onChange, onBlur, ...rest }) => {
    const className = cx(s, 'text-field', { rounded });
    return (React.createElement(FormField, { ...rest, value: toStr(value), className: className, onChange: (v, e) => onChange?.(toVal(v), e), onBlur: (v, e) => onBlur?.(toVal(v), e), accept: accept }, (thru) => React.createElement(Input, { ...thru, pattern: pattern })));
    function toStr(val) {
        return val == null ? '' : val;
    }
    function toVal(str) {
        return nullable && str === '' ? null : str;
    }
};
export default TextField;
