import { EventEmitter } from 'events';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { object } from '@ordaos/util';
const eventEmitter = new EventEmitter();
function useStorage(key, storage, init) {
    const keyMapper = useStorageContext();
    const finalKey = keyMapper?.(key) ?? key;
    const [value, setValue] = useState(get);
    useEffect(() => {
        setValue(get);
    }, [keyMapper]);
    useEffect(() => {
        eventEmitter.on(`${finalKey}-update`, handleUpdate);
        return () => {
            eventEmitter.off(`${finalKey}-update`, handleUpdate);
        };
    }, [finalKey]);
    return [value, set];
    function get() {
        if (!finalKey)
            return init;
        if (!has(storage, finalKey) && finalKey && init) {
            set(init);
            return init;
        }
        return JSON.parse(storage.getItem(finalKey) ?? null);
    }
    function set(value) {
        if (finalKey === null) {
            setValue(value);
            return;
        }
        storage.setItem(finalKey, JSON.stringify(value));
        eventEmitter.emit(`${finalKey}-update`, value);
    }
    function handleUpdate(value) {
        if (value != null) {
            setValue(value);
        }
        else {
            setValue(null);
            storage.removeItem(finalKey);
        }
    }
}
export default useStorage;
function has(storage, key) {
    return object.exists(storage.getItem(key));
}
export function useSession(key, init) {
    return useStorage(key, sessionStorage, init);
}
export function useLocal(key, init) {
    return useStorage(key, localStorage, init);
}
export function clearStorage(storage, pattern) {
    Object.keys(storage)
        .filter((key) => pattern.test(key))
        .forEach((key) => {
        storage.removeItem(key);
    });
}
const StorageContext = createContext((key) => key);
export const StorageContextProvider = ({ children, keyMapper, }) => {
    return (React.createElement(StorageContext.Provider, { value: keyMapper }, children));
};
export function useStorageContext() {
    return useContext(StorageContext);
}
