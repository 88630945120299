import { useRef } from 'react';
import useWindowEvent from "./use-window-event.js";
export default function useClickOutside(fn) {
    const ref = useRef();
    useWindowEvent('click', (evt) => {
        if (ref.current && !ref.current.contains(evt.target)) {
            fn(evt);
        }
    });
    return ref;
}
