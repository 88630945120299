import React from 'react';
import { useForm } from "../Form/form-context.js";
import useUuid from "../../hooks/use-uuid.js";
import useStatelyProp from "../../hooks/use-stately-prop.js";
import Label from "../Label/index.js";
import cx from "../../lib/classnames.js";
import * as s from './FormField.module.scss';
function FormField(props) {
    const { value, name, label, children, labelPlacement, ...rest } = props;
    const [inputValue, setInputValue] = useStatelyProp(value || '');
    const inputName = useUuid(name);
    const form = useForm();
    const placement = labelPlacement ?? form.labelPlacement;
    const showCaption = !['left', 'right'].includes(placement) && props.caption;
    const title = showCaption ? null : props.caption;
    const className = cx(s, 'form-field', {
        [`label-${placement}`]: placement,
    }, props.className);
    return (React.createElement("div", { className: className },
        props.label && (React.createElement(Label, { size: props.size, htmlFor: inputName, title: title }, props.label)),
        showCaption && (React.createElement("span", { className: 'ord-u-type-preset-12' }, props.caption)),
        children({
            ...rest,
            id: inputName,
            name: inputName,
            value: inputValue,
            onChange: (e) => {
                setInputValue(e.target.value);
                return props.onChange?.(e.target.value, e);
            },
            onBlur: (e) => {
                return props.onBlur?.(inputValue, e);
            },
        })));
}
export default FormField;
