import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Loading } from "../components/Icon/index.js";
function useAsync(fn, deps) {
    const [out, setOut] = useState({ status: 'pending' });
    useEffect(() => {
        run();
    }, deps);
    return out;
    async function run() {
        try {
            setOut({ status: 'running' });
            const value = await fn();
            setOut({ status: 'completed', value });
        }
        catch (error) {
            setOut({ status: 'failed', error });
        }
    }
}
export default useAsync;
export function Async(props) {
    const { value, children, loader, failed, overlay } = props;
    const [lastValue, setLastValue] = useState(null);
    const loading = value.status === 'pending' || value.status === 'running';
    useEffect(() => {
        if (value.status === 'completed') {
            setLastValue(value.value);
        }
    }, [value.status]);
    return (React.createElement(React.Fragment, null,
        loading &&
            loader !== false &&
            (loader ?? (React.createElement(React.Fragment, null,
                lastValue && children(lastValue),
                React.createElement("div", { className: cx('ord-u-overlay', {
                        hidden: !overlay,
                    }) },
                    React.createElement(Loading, null))))),
        value.status === 'failed' &&
            failed !== false &&
            (failed?.(value.error) ?? (React.createElement("div", { className: 'ord-u-flex-center' }, "An error occurred - try reloading the page."))),
        value.status === 'completed' && children(value.value)));
}
