"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YEAR = exports.WEEK = exports.DAY = exports.HOUR = exports.MINUTE = exports.SECOND = exports.MILLI = exports.AMINO_ACIDS = void 0;
// hydrophobicity source: https://www.imgt.org/IMGTeducation/Aide-memoire/_UK/aminoacids/IMGTclasses.html
exports.AMINO_ACIDS = [
    { name: 'alanine', short: 'ALA', acid: 'A', hydrophobicity: 'hydrophobic' },
    {
        name: 'arginine',
        short: 'ARG',
        acid: 'R',
        hydrophobicity: 'hydrophilic',
    },
    {
        name: 'asparagine',
        short: 'ASN',
        acid: 'N',
        hydrophobicity: 'hydrophilic',
    },
    {
        name: 'aspartic acid',
        short: 'ASP',
        acid: 'D',
        hydrophobicity: 'hydrophilic',
    },
    {
        name: 'cysteine',
        short: 'CYS',
        acid: 'C',
        hydrophobicity: 'hydrophobic',
    },
    {
        name: 'glutamine',
        short: 'GLN',
        acid: 'Q',
        hydrophobicity: 'hydrophilic',
    },
    {
        name: 'glutamic acid',
        short: 'GLU',
        acid: 'E',
        hydrophobicity: 'hydrophilic',
    },
    { name: 'glycine', short: 'GLY', acid: 'G', hydrophobicity: 'neutral' },
    { name: 'histidine', short: 'HIS', acid: 'H', hydrophobicity: 'neutral' },
    {
        name: 'isoleucine',
        short: 'ILE',
        acid: 'I',
        hydrophobicity: 'hydrophobic',
    },
    { name: 'leucine', short: 'LEU', acid: 'L', hydrophobicity: 'hydrophobic' },
    { name: 'lysine', short: 'LYS', acid: 'K', hydrophobicity: 'hydrophilic' },
    {
        name: 'methionine',
        short: 'MET',
        acid: 'M',
        hydrophobicity: 'hydrophobic',
    },
    {
        name: 'phenylalanine',
        short: 'PHE',
        acid: 'F',
        hydrophobicity: 'hydrophobic',
    },
    { name: 'proline', short: 'PRO', acid: 'P', hydrophobicity: 'neutral' },
    { name: 'serine', short: 'SER', acid: 'S', hydrophobicity: 'neutral' },
    { name: 'threonine', short: 'THR', acid: 'T', hydrophobicity: 'neutral' },
    {
        name: 'tryptophan',
        short: 'TRP',
        acid: 'W',
        hydrophobicity: 'hydrophobic',
    },
    { name: 'tyrosine', short: 'TYR', acid: 'Y', hydrophobicity: 'neutral' },
    { name: 'valine', short: 'VAL', acid: 'V', hydrophobicity: 'hydrophobic' },
];
exports.MILLI = 1;
exports.SECOND = 1000 * exports.MILLI;
exports.MINUTE = 60 * exports.SECOND;
exports.HOUR = 60 * exports.MINUTE;
exports.DAY = 24 * exports.HOUR;
exports.WEEK = 7 * exports.DAY;
exports.YEAR = 365 * exports.DAY;
