import React, { useEffect, useState } from 'react';
import { allByType } from 'react-children-by-type';
import useWindowEvent from "../../hooks/use-window-event.js";
import cx from "../../lib/classnames.js";
import Modal, { useModal } from "../Modal/index.js";
import * as s from './Shortcuts.module.scss';
// --- TOP LEVEL ---
const [useGroups, updateGroups] = createSingletonState([]);
function Shortcuts({ group, children }) {
    const shortcuts = allByType(children, Shortcut).map((child) => child.props);
    const isSingleton = useSingleton();
    const addedGroups = useGroups();
    const modal = useModal();
    const groups = [
        {
            name: 'Global',
            shortcuts: [
                {
                    label: 'Show all shortcuts',
                    shift: true,
                    char: '?',
                    action: modal.onOpen,
                },
            ],
        },
        ...addedGroups,
    ];
    useShortcuts(isSingleton ? groups.flatMap((g) => g.shortcuts) : []);
    useEffect(() => {
        const newShortcuts = shortcuts.filter((s) => !addedGroups.some((g) => g.shortcuts.includes(s)));
        updateGroups((current) => [
            ...current,
            { name: group, shortcuts: newShortcuts },
        ]);
        return () => {
            updateGroups((current) => current.filter((g) => g.name !== group));
        };
    }, []);
    return isSingleton ? (React.createElement("div", { className: cx(s, 'shortcuts') },
        React.createElement(ShortcutsModal, { groups: groups, ...modal }))) : null;
}
export default Shortcuts;
function useShortcuts(shortcuts) {
    const shortcutLabels = shortcuts.map((s) => s.label).join(',');
    useWindowEvent('keydown', (e) => {
        e.preventDefault();
        const shortcut = shortcuts.find((s) => !!s.alt === !!e.altKey &&
            !!s.cmd === !!e.metaKey &&
            !!s.ctrl === !!e.ctrlKey &&
            !!s.shift === !!e.shiftKey &&
            (s.char === e.key ||
                e.code.match(new RegExp(`Key${s.char.toUpperCase()}`))));
        return shortcut?.action();
    }, null, [shortcutLabels]);
    return;
}
function ShortcutsModal({ groups, ...props }) {
    // prettier-ignore
    return (React.createElement(Modal, { ...props },
        React.createElement("h2", { className: cx(s, 'shortcuts-modal-heading') }, "Active Shortcuts"),
        React.createElement("ul", { className: cx(s, 'shortcuts-modal-groups') }, groups.map(({ name, shortcuts }, i) => (React.createElement("li", { key: `shortcut-group-${i}`, className: cx(s, 'shortcuts-modal-group') },
            React.createElement("h3", { className: cx(s, 'shortcuts-modal-group-heading') }, name),
            React.createElement("ul", { className: cx(s, 'shortcuts-modal-group-shortcuts') }, shortcuts.map((shortcut) => {
                const cmd = toKeydownDef(shortcut);
                return (React.createElement("li", { key: cmd, className: cx(s, 'shortcuts-modal-group-shortcut') },
                    cmd,
                    ": ",
                    shortcut.label));
            }))))))));
}
class RenderCount extends EventTarget {
    #count = 0;
    increment() {
        this.#count++;
        this.dispatchEvent(new Event('inc'));
        return this.#count;
    }
    decrement() {
        this.#count--;
        this.dispatchEvent(new Event('dec'));
        return this.#count;
    }
}
const count = new RenderCount();
function useSingleton() {
    const [index, setIndex] = useState(0);
    useEffect(() => {
        setIndex(count.increment());
        count.addEventListener('dec', promote);
        return () => {
            count.removeEventListener('dec', promote);
            count.decrement();
        };
        function promote() {
            setIndex((i) => i - 1);
        }
    }, []);
    return index === 1;
}
function toKeydownDef(shortcut) {
    const { alt, cmd, ctrl, shift, char } = shortcut;
    const os = getOS();
    const altKey = os === 'Mac' ? '⌥ + ' : 'Alt + ';
    const cmdKey = os === 'Mac' ? '⌘ + ' : '⊞ + ';
    const ctrlKey = os === 'Mac' ? '^ + ' : 'Ctrl + ';
    return `${alt ? altKey : ''}${cmd ? cmdKey : ''}${ctrl ? ctrlKey : ''}${shift ? 'Shift + ' : ''}'${char}'`;
}
function getOS() {
    if (typeof window === 'undefined')
        return 'Windows';
    return window.navigator.userAgent.match(/(Mac|iPhone|iPod|iPad)/i)
        ? 'Mac'
        : 'Windows';
}
function Shortcut(props) {
    return React.createElement(React.Fragment, null);
}
Shortcuts.Shortcut = Shortcut;
function createSingletonState(initialValue) {
    let _updaters = [];
    let _value = initialValue;
    const useSingleton = () => {
        const [value, update] = useState(_value);
        useEffect(() => {
            _updaters.push(update);
            return () => {
                _updaters = _updaters.filter((el) => el !== update);
            };
        }, []);
        return value;
    };
    const updateSingleton = (updateValue) => {
        _value =
            typeof updateValue === 'function'
                ? // @ts-ignore
                    updateValue(_value)
                : updateValue;
        _updaters.forEach((cb) => cb(_value));
    };
    return [useSingleton, updateSingleton];
}
