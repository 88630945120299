import React, { useState } from 'react';
import { scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { allByType } from 'react-children-by-type';
import { object } from '@ordaos/util';
import * as t from '../../_tokens/index.theo.module';
import BaseChart from "../BaseChart.js";
function HeatMap({ items: itemsRaw, rowToLabel, gap = 2, columnCorrection = false, toTooltip = (r, c, v) => `(${r.label}, ${c.label}) - ${v}`, children, ...opts }) {
    const [tooltip, setTooltip] = useState(null);
    const columns = allByType(children, Column).map(object.pluck('props'));
    return (React.createElement(BaseChart, { width: 600, marginBottom: 100, marginRight: 20, tooltip: tooltip, data: Promise.resolve(itemsRaw), ...opts }, (items) => {
        const hydratedItems = items.reduce((acc, item, i) => {
            return {
                rows: [
                    ...acc.rows,
                    {
                        label: rowToLabel(item),
                        values: columns.map((c) => object.value(c.value)(item)),
                        item,
                    },
                ],
                values: [
                    ...acc.values,
                    ...columns.map((c) => object.value(c.value)(item)),
                ],
            };
        }, { rows: [], values: [] });
        const isColumnScaled = columnCorrection ||
            columns.reduce((colScale, c) => colScale ||
                c.min !== undefined ||
                c.max !== undefined, false);
        const columnColorScales = columns.map((c) => {
            const values = items.map(object.value(c.value));
            return scaleLinear({
                domain: [
                    c.min ?? Math.min(...values),
                    c.max ?? Math.max(...values),
                ],
                range: [t.colorWhite, t.colorLightGreen],
            });
        });
        const { rows, values } = hydratedItems;
        const binHeight = opts.height / rows.length || 300 / rows.length;
        const binWidth = opts.width / columns.length || 600 / columns.length;
        const colorScale = scaleLinear({
            domain: [Math.min(...values), Math.max(...values)],
            range: [t.colorWhite, t.colorLightGreen],
        });
        const xScale = scaleLinear({
            domain: [0, columns.length],
            range: [0, opts.width || 600],
        });
        const yScale = scaleLinear({
            domain: [0, rows.length],
            range: [0, opts.height || 300],
        });
        return (React.createElement(React.Fragment, null,
            children,
            rows.map((row, i) => {
                const rowLabel = row.label;
                return (React.createElement(Group, { transform: `translate(0,${i * binHeight})`, key: `${row.label}-${row.values.join('')}` },
                    React.createElement(Text, { verticalAnchor: 'middle', dx: xScale(columns.length) + 5, dy: binHeight / 2 }, rowLabel),
                    row.values.map((value, j) => {
                        return (React.createElement(Group, { transform: `translate(${xScale(j)},${gap})`, onMouseOut: () => setTooltip(null), key: `${value}-${j}` },
                            React.createElement("rect", { fill: isColumnScaled
                                    ? columnColorScales[j](value)
                                    : colorScale(value), width: binWidth - gap * 2, rx: 5, height: binHeight - gap * 2, onMouseOver: () => setTooltip({
                                    left: xScale(j),
                                    top: yScale(i),
                                    text: toTooltip(row, columns[j], value),
                                }) })));
                    })));
            }),
            columns.map((c, i) => {
                return (React.createElement(Text, { textAnchor: 'start', angle: 90, dx: xScale(i) + binWidth / 2 - gap, dy: yScale(rows.length) + 15, key: `${c.label}-${i}` }, c.label));
            })));
    }));
}
HeatMap.Column = Column;
export default HeatMap;
function Column(props) {
    return null;
}
