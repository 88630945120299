import React from 'react';
import cx from "../../lib/classnames.js";
import Button from "../Button/index.js";
import * as s from './Table.module.scss';
function Cell({ item, collapsible, numRows, cellHighlight, ...col }) {
    const { collapsed, onToggle } = collapsible ?? {};
    const type = typeof col.value(item);
    const style = !!cellHighlight
        ? { background: cellHighlight(item) }
        : undefined;
    return (React.createElement("td", { className: cx(s, 'table--body-cell', {
            collapsed,
            [type]: true,
            [col.align]: !!col.align,
        }), rowSpan: collapsed ? numRows : 1, onClick: collapsed ? onToggle : undefined, style: style }, collapsed ? (React.createElement(Button, { icon: true, onClick: onToggle },
        React.createElement("div", { title: col.title, className: cx(s, 'table--body-cell--collapsed-label') }, col.heading))) : (getDisplay())));
    function getDisplay() {
        if (col.html)
            return col.html(item);
        const v = col.value(item);
        return col.format?.(v) ?? v;
    }
}
export default Cell;
