import React, { useState } from 'react';
import useWindowEvent from "../../hooks/use-window-event.js";
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleLinear } from '@visx/scale';
import { curveNatural } from '@visx/curve';
import { Text } from '@visx/text';
import { LinePath } from '@visx/shape';
import { Group } from '@visx/group';
import { localPoint } from '@visx/event';
import { allByType } from 'react-children-by-type';
import { object, array } from '@ordaos/util';
import BaseChart from "../BaseChart.js";
import * as t from '../../_tokens/index.theo.module';
import cx from "../../lib/classnames.js";
import * as s from './LineGraph.module.scss';
function LineGraph({ children, showXTicks = true, showYTicks = true, xAxis, yAxis, withLegend, toTooltip, toTooltipHeading, ...opts }) {
    const [hovered, setHovered] = useState(null);
    const [active, setActive] = useState([]);
    const [mouseCoords, setMouseCoords] = useState(null);
    const linesRaw = allByType(children, Line).map(object.pluck('props'));
    const lineLabelLengths = linesRaw.map((l) => getLabel(l).length);
    const marginRight = withLegend
        ? Math.max(...lineLabelLengths) * 7.3 + 50
        : 20;
    const marginLeft = opts.marginLeft ?? 40;
    const [width, setWidth] = useState(opts.width ?? window.innerWidth - 120 - marginRight - marginLeft);
    useWindowEvent('resize', (evt) => {
        // @ts-ignore
        setWidth(evt.currentTarget.innerWidth - 120 - marginRight - marginLeft);
    });
    return (React.createElement("div", { className: cx(s, 'line-graph') },
        React.createElement(BaseChart, { width: width, onMouseLeave: () => {
                setHovered(null);
                setMouseCoords(null);
            }, onMouseMove: (e) => {
                const coords = localPoint(
                // @ts-ignore
                e.target, e);
                // @ts-ignore
                if (!e.target.ownerSVGElement) {
                    setHovered(null);
                }
                setMouseCoords(coords);
            }, marginLeft: 40, marginBottom: 40, marginRight: marginRight, marginTop: 20, data: Promise.all(linesRaw.map(async (line) => {
                const values = await Promise.resolve(line.values);
                return { ...line, values };
            })), ...opts }, (lines) => {
            const filteredLines = lines.filter(({ values }) => {
                return (!!values.some((v) => typeof v === 'number') &&
                    !values.some((v) => isNaN(v)));
            });
            const xRange = xAxis?.range ?? [
                0,
                Math.max(...filteredLines.map((line) => line.values.length - 1)),
            ];
            const yRange = yAxis?.range ?? [
                Math.min(...filteredLines.reduce((vs, l) => vs.concat(l.values), [0])),
                Math.max(...filteredLines.reduce((vs, l) => vs.concat(l.values), [])),
            ];
            const xScale = scaleLinear({
                domain: xRange,
                range: [0, width],
            });
            const yScale = scaleLinear({
                domain: yRange,
                range: [opts.height ?? 300, 0],
            });
            const mouseX = Math.floor(xScale.invert(mouseCoords?.x - (opts.marginLeft ?? 30)) + 0.5);
            const mouseInRange = mouseCoords?.x - (opts.marginLeft ?? 30) < width &&
                Math.min(...xRange) <= mouseX &&
                mouseX <= Math.max(...xRange);
            return (React.createElement(React.Fragment, null,
                withLegend && (React.createElement(Group, { transform: `translate(${xScale(Math.max(...xRange)) + 30}, ${yScale(Math.max(...yRange))})` },
                    React.createElement(Text, { fontFamily: 'monospace', fontSize: 12, fontWeight: 'bold' }, "Legend:"),
                    lines.map((line, i) => {
                        const isHovered = getLabel(hovered) === getLabel(line);
                        const isActive = checkActive(line) || isHovered;
                        const label = getLabel(line);
                        const isEmpty = !line.values.some((v) => typeof v === 'number' &&
                            !isNaN(v));
                        return (React.createElement(React.Fragment, { key: `${getLabel(line)}-tooltip` },
                            React.createElement(Text, { transform: `translate(10, ${(i + 1) * 15})`, fontFamily: 'monospace', fill: getColor(isEmpty
                                    ? 'red'
                                    : line.color, isActive), fontSize: 12, cursor: 'pointer', onClick: () => setActive(array.toggleIn(active, line, (a, b) => {
                                    return (getLabel(a) ===
                                        getLabel(b));
                                })) }, `${label} ${isEmpty ? '!' : ''}`)));
                    }),
                    active.length > 0 && (React.createElement(Text, { transform: `translate(0, ${(lines.length + 2) * 15})`, fontFamily: 'monospace', fill: 'black', fontSize: 12, cursor: 'pointer', onClick: () => setActive([]) }, "Show All")))),
                React.createElement(Group, { transform: `translate(0, ${yScale(0)})` },
                    React.createElement(AxisBottom, { scale: xScale, label: xAxis?.label, tickValues: showXTicks || active.length > 0
                            ? xScale.ticks()
                            : [] })),
                React.createElement(AxisLeft, { scale: yScale, label: yAxis?.label, tickValues: showYTicks || active.length > 0
                        ? yScale.ticks()
                        : [] }),
                filteredLines.map((line, i) => {
                    const { values, dashed, curved } = line;
                    const labelValue = getLabel(line);
                    const isHovered = getLabel(hovered) === labelValue;
                    const isActive = checkActive(line) || isHovered;
                    if (active.length > 0 && !isActive) {
                        return;
                    }
                    return (React.createElement(React.Fragment, { key: `${values.join('')}-${i}` },
                        React.createElement(LinePath, { onMouseOver: () => setHovered(line), data: values, curve: curved ? curveNatural : null, fill: 'none', stroke: getColor(line.color, isActive), strokeWidth: isHovered ? 3 : 1, strokeDasharray: dashed ? 4 : null, x: (_, i) => xScale(i), y: (d) => yScale(d), defined: object.exists, key: `Line-${values.join('')}-${i}` }),
                        mouseInRange && isActive && (React.createElement("circle", { r: 3, fill: 'black', cx: xScale(mouseX), cy: yScale(values[mouseX]) }))));
                }),
                toTooltip && mouseInRange && (React.createElement(Group, { transform: `translate(10, ${yScale(Math.max(...yRange))})` },
                    toTooltipHeading && (React.createElement(React.Fragment, null,
                        React.createElement("rect", { fill: 'white', transform: `translate(-3, -10)`, width: toTooltipHeading(mouseX)
                                .length * 7.3, height: '15' }),
                        React.createElement(Text, { fontFamily: 'monospace', fontSize: 13 }, toTooltipHeading(mouseX)))),
                    hovered && active.length === 0 && (React.createElement(React.Fragment, null,
                        React.createElement("rect", { fill: 'white', transform: `translate(-3, 5)`, width: toTooltip(getLabel(hovered), mouseX, hovered?.values[mouseX]).length * 7.3, height: '15' }),
                        React.createElement(Text, { fontFamily: 'monospace', fontSize: 12, transform: `translate(0, 15)` }, toTooltip(getLabel(hovered), mouseX, hovered?.values[mouseX])))),
                    active.map((l, i) => {
                        const value = l.values[mouseX];
                        const hasValue = typeof value === 'number' &&
                            !isNaN(value);
                        return (React.createElement(React.Fragment, { key: `${JSON.stringify(l)}-${i}` },
                            React.createElement("rect", { fill: 'white', transform: `translate(-3, ${(i + 1) * 15 - 10})`, width: toTooltip(getLabel(l), mouseX, l?.values[mouseX]).length * 7.3, height: '15' }),
                            React.createElement(Text, { fontFamily: 'monospace', fontSize: 12, transform: `translate(0, ${(i + 1) * 15})`, key: `${getLabel(l)}-${i}-tooltip` }, toTooltip(getLabel(l), mouseX, value, !hasValue && 'No Data'))));
                    })))));
        })));
    function getColor(color, isActive) {
        if (!color) {
            return t.colorGray;
        }
        if (hovered || active.length > 0) {
            return isActive ? color : t.colorGray;
        }
        return color;
    }
    function checkActive(line) {
        return !!active.find((l) => getLabel(l) === getLabel(line));
    }
}
export default LineGraph;
function getLabel(line) {
    if (!line)
        return null;
    return typeof line.children === 'function'
        ? line.children(line)
        : line.children;
}
const Line = (LineGraph.Line = (props) => {
    return null;
});
